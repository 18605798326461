import { Translation } from "../interfaces";

export const frenchUI: Translation = {
    dashboard: "Tableau de bord",
    surveys: "Enquêtes",
    reports: "Rapports",
    feedback: "Commentaires",
    actionPlanning: "Action Planning",
    admin: "Administrateurs",
    profile: "Profil",
    settings: "Paramètres",
    help: "Aidez-moi",
    logout: "Se déconnecter",
    home: "Accueil",
    contactUs: "Nous contacter",
    loginError: "Erreur d'identification",
    loginErrorMsg: "Votre compte a été désactivé. Veuillez nous contacter à support@talentmap.com.",
    pageNotFound: "404 - Page non trouvée",
    sorryCantSeemPage: "Désolé, nous ne parvenons pas à trouver ce que vous recherchez.",
    pageNotExistOrRemoved: "La page n'existe pas ou a été supprimée",
    goHomepage: "aller à la page d'accueil",
    goalsChallenges: "Objectifs et défis",
    organizations: "Organisations",
    users: "Utilisateurs",
    noOptionsAvailable: "aucune option disponible",
    adminSurveys: "Enquêtes",
    refreshPage: "Actualiser la page",
    internalServerError: "500 - Erreur Interne du Serveur",
    somethingWentWrong: "Désolé, quelque chose s'est mal passé",
    workingToFix: "Nous travaillons pour résoudre le problème",
    addOrganization: "ajouter une organisation",
    import: "importer",
    addUser: "Ajouter un utilisateur",
    information: "Information",
    tags: "Mots clés",
    deactivate: "désactiver",
    reactivate: "réactiver",
    name: "Nom",
    yearEstablished: "Année de création",
    employeeCount: "Nombre d'employés",
    industry: "Industrie",
    type: "Type",
    logo: "Logo",
    responses: "Réponses",
    uploadLogo: "Télécharger le logo",
    dragDropFilesHereOrClick: "Joindre le fichier ici",
    dropHere: "déposer ici",
    pngTransparent: "PNG avec un fond transparent",
    errorMustUploadPNG: "Vous devez télécharger un fichier PNG",
    cannotBeDroppedHere: "Ce type de fichier ne peut pas être déposé ici",
    organizationHasBeenDeactivated: "L'organisation a été désactivée",
    organizationHasBeenReactivated: "L'organisation a été réactivée",
    general: "Général",
    reportScales: "Échelles de rapport",
    benchmarks: "Références",
    dimensions: "Dimensions",
    surveyNameCannotBeBlank: "Le nom de l'enquête ne peut pas être vide",
    orgNameCannotBeBlank: "Le nom de l'organisation ne peut pas être vide",
    cancel: "Annuler",
    save: "Enregistrer",
    organizationSaveSuccessfully: "Organisation enregistrée avec succès",
    organizationInformationLabel: "Informations sur l'organisation",
    noFileAttached: "Aucun fichier joint",
    uploadSurvey: "Importer l'enquête",
    upload: "Télécharger",
    noLogoUploaded: "Aucun logo téléchargé",
    deleteLogo: "Supprimer le logo",
    numberOfRows: "Number of rows",
    goBack: "supprimer et recommencer",
    completeUpload: "Terminer le Téléchargement",
    uploadSurveyPreview: "Aperçu d'importatio",
    noOrganizationSelected: "Aucune organisation sélectionnée",
    deactivated: "désactivé",
    active: "actif",
    selectBeforeManagingSurveys: "Sélectionnez avant de gérer les enquêtes",
    errorLoadingContactUs:
        "Une erreur s'est produite lors du chargement. Si ce problème persiste, veuillez nous contacter.",
    errorLoadingCSV: "Erreur lors du chargement du fichier CSV",
    errorMustUploadCSV: "Vous devez télécharger un fichier CSV",
    surveyFileCSV: "Fichier d'enquête (.csv)",
    responseFileCSV: "Fichier de réponse (.csv)",
    scaleFileCSV: "Fichier d'échelle (.csv)",
    startDate: "Date de début",
    endDate: "Date de fin",
    closeDate: "Date de fin",
    required: "obligatoire",
    surveyHasBeenDeactivated: "L'enquête a été désactivée",
    surveyHasBeenReactivated: "L'enquête a été réactivée",
    errorUpdatingContactUs:
        "Une erreur s'est produite lors de la mise à jour. Si ce problème persiste, veuillez nous contacter.",
    dateCreated: "Date de Creation",
    createdBy: "Créé Par",
    orgChart: "Organigramme",
    yearEstablishedMustBeNumber: "L'année d'établissement doit être un nombre",
    yearEstablishedMustBe4Digits: "L'année d'établissement doit comporter 4 chiffres (par ex.: 1983)",
    minimumYearEstablished: "L'année minimum autorisée est ",
    maximumYearEstablished: "L'année maximale autorisée est ",
    reportWillBeUnlockSurveyCreated: "Les rapports seront déverrouillés une fois qu'une enquête aura été créée.",
    startSurveyClickSurveyOrUploadSurvey: `Téléchargez une enquête en cliquant sur "Admin".`,
    reportWillBeUnlockReportViewer:
        "Les rapports seront déverrouillés une fois qu'un administrateur du site aura téléchargé les données de réponse à l'enquête.",
    email: "Email",
    isCommentViewer: "Voir les commentaires",
    canCreateSurveys: "Créer des sondages",
    isSiteAdmin: "Administrateur du site",
    userInformationLabel: "Informations de l'utilisateur",
    source: "La source",
    noActiveUser: "Ajouter un utilisateur",
    inviteUser: "Les utilisateurs ajoutés à l'organisation apparaîtront ici",
    noDeactivatedUSer: "Il n'y a pas d'utilisateurs désactivés",
    usersRemovedFromOrg: "Les utilisateurs supprimés de l'organisation sont placés ici.",
    isSurveyCreator: "Créateur de l'enquête",
    talentMapAdmin: "Administrateur TalentMap",
    noSurveyInformationSelected: "Aucune information d'enquête sélectionnée",
    selectSurveyInformation: "Sélectionnez les informations d'enquête",
    role: "Rôle",
    powerPoint: "PowerPoint",
    excel: "Excel",
    export: "Exportation",
    live: "Temps réel",
    apply: "Appliquer",
    responseRateReport: "Rapport sur le taux de réponse",
    emptyData: "<Valeur vide>",
    newUserHasBeenCreated: "Le nouveau utilisateur a été créé",
    userHasBeenDeactivated: "L'utilisateur a été désactivé",
    userHasBeenReactivated: "L'utilisateur a été activé de nouveau",
    userNameCannotBeBlank: "Le nom de l'utilisateur ne peut pas être vide",
    userEmailCannotBeBlank: "Le courriel de l'utilisateur ne peut pas être vide",
    userEmailCannotBeInvalid: "L'adresse e-mail de l'utilisateur ne peut pas être invalide",
    userCreateError: "La création de l'utilisateur a échoué, veuillez vérifier votre saisie ou réessayer tardr",
    userEmailError: "Cette adresse e-mail a peut-être été utilisée",
    surveyNameMustBeUnique: "Le nom de l'enquête doit être unique",
    duplicatedLiveSurveyName: "Vous avez une enquête en direct avec des noms en double",
    unableSaveSurvey: "Impossible d'enregistrer l'enquête",
    organizationNameMustBeUnique: "Le nom de l'organisation doit être unique",
    addSurveyResult: "Ajouter un résultat d'enquête",
    campaign: "Campagnes",
    resultOptimization: "Configuration des résultats du sondage",
    survey: "Enquêtes",
    selectSurvey: "Veuillez sélectionner une enquête",
    campaignName: "Nom",
    confirm: "Confirmer",
    results: "Résultats",
    create: "Créer",
    surveyGizmoLoadingError:
        "Impossible de charger les enquêtes à partir de Survey Gizmo, veuillez essayer d'actualiser la page",
    noSGCampaigns: "Aucune campagne disponible",
    noSGSurveys: "Aucune enquête disponible",
    confidentialityThreshold: "Seuil de confidentialité",
    belowConfidentialityThreshold: "Sous le seuil de confidentialité",
    noActiveOrganizations: "Aucune organisation active",
    noActiveOrganizationsMessage:
        'Cliquez sur le bouton "Ajouter une organisation" en haut pour ajouter une nouvelle organisation.',
    noDeactivatedOrganizations: "Aucune organisation n'est désactivée",
    noDeactivatedOrganizationsMessage: "Les organisations désactivées sont placées ici.",
    noResultsCreated: "Aucun résultat n'est créé",
    noResultsCreatedMessage:
        'Cliquez sur le bouton "Ajouter un résultat d\'enquête" en haut pour ajouter un nouveau résultat.',
    noDeactivatedResults: "Aucun résultat n'est désactivé",
    noDeactivatedResultsMessage: "Les résultats désactivés sont placés ici.",
    permission: "Autorisation",
    continue: "Continuer",
    surveyResults: "Résultats du sondage",
    search: "Chercher",
    switchOrganization: "Changer d'organisation",
    responseRate: "Taux de réponse",
    change: "changer",
    reportBreakdown: "Répartition du rapport",
    contactField: "Démographique",
    surveyItem: "Élément d'enquête",
    overallResponseRate: "Taux de réponses global",
    surveysSent: "Envoyées",
    surveysCompleted: "Terminés",
    responseRatePercentage: " Taux de réponses (%)",
    collapseAll: "Tout réduire",
    expandAll: "Développer tout",
    toSeeBreakdownReport:
        "Pour voir une ventilation du rapport, sélectionnez un élément dans la liste déroulante à gauche.",
    clickThePlusIconToAddMoreBreakdown:
        "Cliquez sur l'icône plus pour ajouter d'autres éléments à la répartition du rapport.",
    clickThePlusButtonToAddANewFilter: "Cliquez sur le bouton plus pour ajouter un nouveau filtre",
    canViewAllOrganizationReportData: "Afficher toutes les données du rapport de l'organisation",
    automap: "Automap",
    selectBenchmark: "Sélectionnez un benchmark",
    campaigns: "Campagne",
    invalidConfidentialityThreshold: "Seuil de confidentialité non valide",
    surveyResultName: "Nom du résultat de l'enquête",
    compass: "Compass",
    imported: "Importé",
    reportViewerWarning:
        "La sélection de l'autorisation annulera l'attribution de l'utilisateur des cartes qui pourraient lui être attribuées",
    none: "aucun",
    noOrgChartAvailable: "Aucun organigramme disponible",
    noOrgChartAvailableMsg: "Pour le moment, vous n'avez pas encore été affecté à une carte dans l'organigramme.",
    noOrgChartAvailableContact: "Veuillez contacter votre administrateur pour obtenir de l'aide.",
    filters: "Filtres",
    filter: "Filtre",
    equalTo: "Égal à",
    notEqualTo: "Pas égal à",
    greaterThan: "Plus grand que",
    lessThan: "Moins que",
    and: "ET",
    or: "OU",
    date: "Date",
    showBenchmarkInReports: "Afficher la référence dans les rapports",
    confirmAutomapTitle: "Mappage automatique de la référence?",
    confirmAutomapMessage: "Mappage automatique de la référence effacera toutes les références actuellement mappées.",
    benchmarkMappingHelp: [
        "Cliquez sur le bouton Automap pour associer automatiquement les éléments de référence aux éléments de votre enquête.",
        "Tous les éléments qui n'ont pas pu être mappés automatiquement seront signalés par un arrière-plan rouge.",
        "Les éléments non mappés peuvent être mappés manuellement en sélectionnant la section appropriée, puis l'élément à partir de la référence."
    ],
    benchmarkMappingEmptyState: [
        "Pour commencer, sélectionnez un type de référence dans la liste déroulante ci-dessus.",
        "Pour obtenir de l'aide sur la façon de faire une cartographie de référence, reportez-vous à l'icône d'aide."
    ],
    noBenchmark: "Pas de référence",
    switchBenchmark: "Changer de référence?",
    switchBenchmarkMsg: "Le changement de référence effacera tous les références actuellement mappées.",
    customScale: "Éléments de l'enquête",
    defaultSettings: "Échelles par défaut",
    newFilter: "Nouveau filtre",
    clearFilters: "Effacer les filtres",
    useSavedFilter: "Utiliser le filtre enregistré",
    saveFilter: "Enregistrer le filtre",
    filterNotValid: "Filter not valid",
    nameMustBeUnique: "Nom doit être unique",
    nameCannotBeEmpty: "Nom ne peut pas être vide",
    dateResponseSubmitted: "Dernière réponse soumise",
    customScaleHelp: [
        "Remplacer par défaut - Permet de définir les échelles personnalisées pour la question à l'aide des listes déroulantes ci-dessous.",
        " ",
        "Dans la liste déroulante des paramètres: Inclure dans les rapports - (par défaut) La date de la question est incluse dans tous les aspects des rapports.",
        " ",
        "Ignorer des rapports - Ignorer les données de question de tous les rapports.",
        " ",
        "Inclure dans les rapports mais supprimer du score global de la dimension - La date de la question apparaîtra toujours dans les rapports mais ne sera pas incluse dans le score global.",
        " ",
        `Masquer du visualiseurs de rapports restreint - L'utilisateur sélectionne cela pour masquer la question des rapports pour les visualiseurs de rapports restreints uniquement. Le score de la question est toujours ajouté au score global, mais l'utilisateur ne peut pas voir la répartition des données pour cette question.`
    ],
    defaultScaleCustomized: "Échelle par défaut personnalisée:",
    defaultScaleUsed: "Échelle par défaut utilisée:",
    overrideDefault: "Remplacer les échelles par défaut",
    includeInReports: "Inclure dans le Rapport",
    removeFromReports: "Supprimer du Rapport",
    noOverallScore: "Inclure dans le Rapport mais supprimer du score global de la dimension",
    reportingOptions: "Options du rapport",
    favorable: "Favourable",
    neutral: "Neutre",
    unfavorable: "Défavourable",
    ignored: "Ignoré",
    noFilterSaved: "Pas de filtre sauvegardé",
    noFilterAdded: "Pas de filtre ajouté",
    hideFromRestrictedReportViewer: "Masquer du visualiseurs de rapports restreint",
    customizeIndividualQuestions: "Personnaliser des éléments individuels",
    useNominalScale: "Utiliser l'échelle nominale",
    noData: "Aucun score global de dimension disponible",
    totalResponses: "Total des réponses",
    benchmark: "Référence",
    noSearchResults: "Aucun résultat de recherche trouvé.",
    confidentialityWarning: "Avertissement de confidentialité!",
    confidentialityWarningDescription:
        "Le (s) filtre (s) sélectionné (s) a un résultat avec un nombre de réponses inférieur au seuil de confidentialité. Pour préserver la confidentialité des réponses, nous ne sommes pas en mesure d'afficher ce rapport",
    confidentialityWarningDescriptionAllSubGroup:
        "Tous les groupes de cette diapositive sont en deçà du seuil de confidentialité. Pour préserver la confidentialité des réponses, nous ne sommes pas en mesure d'afficher ce rapport",
    previousPeriod: "Période Précédente",
    noPreviousPeriodMappingCreated: "Aucun mappage de période précédente créé",
    toContinueEnterInformationBelowAndAutomap: `Pour continuer, entrez les informations ci-dessous et cliquez sur "Automap"`,
    accessDenied: "Accès refusé",
    userHasNoAccess: "Vous n'avez pas accès à cette page.",
    PleaseContactUsForLicense:
        "Veuillez nous contacter à support@talentmap.com ou votre administrateur pour obtenir une licence pour accéder à cette page.",
    previousSurvey: "Enquête précédente",
    mappingLabel: "Étiquette de mappage",
    previousSurveyDescription: "Sélectionnez une enquête précédente à mapper avec le résultat actuel de l'enquête.",
    mappingLabelDescription: [
        "Maximum 6 caractères",
        "Cette étiquette sera affichée sur les rapports où les périodes précédentes sont utilisées."
    ],
    currentSurvey: "Enquête actuelle",
    mappingToSurvey: "Mappage à l'enquête",
    showInReports: "Afficher dans les rapports",
    deleteMapping: "Supprimer le mappage",
    mapTo: "Orienté vers",
    remapGroupItems: "Remapper les éléments du groupe",
    clearMappings: "Effacer les mappages",
    clearMapping: "Effacer les mappage",
    editMapping: "Modifier le mappage",
    clickToAddNewMapping: "Cliquez sur le bouton plus pour ajouter un nouveau mappage.",
    deleteMappingMsg: "Cette action supprimera définitivement le mappage.",
    delete: "Effacer",
    mapped: "Orienté",
    previousPeriodMapping: "Cartographie de la période précédente",
    unableToRemapGroup: "Unable to remap group",
    unableToMapValue: "Unable to map value",
    unableToEditSurveyComparison: "Unable to edit survey comparison",
    unableToAutoMap: "Unable to automap",
    thisActionWillPermanentlyRemoveMapping: "Cette action supprimera définitivement le mappage.",
    items: "éléments",
    contacts: "Contacts",
    unableToDeleteSurveyComparison: "Unable to delete survey comparison",
    editItemMapping: "Modifier le mappage des éléments",
    responseRateEmptyMessage:
        "Pour continuer, veuillez sélectionner une enquête dans la liste déroulante située en haut à gauche de la page.",
    openSurveys: "Sondages Enquêtes",
    demographics: "Démographique",
    responseRateHelpText: [
        "Utilisez la liste déroulante pour ventiler le taux de réponse global par démographie. (Par exemple: afficher le taux de réponse pour chaque département).",
        "Vous pouvez détailler le rapport sur le taux de réponse en cliquant sur le bouton plus pour ajouter des listes déroulantes supplémentaires (par exemple: afficher le taux de réponse pour chaque groupe d'âge au sein d'un département)."
    ],
    responseRateEmptyBreakdownPageText: [
        "Le filtre sélectionné ne contient aucune réponse au sondage.",
        "Veuillez mettre à jour la selection du filtre."
    ],
    lastUpdated: "dernière mise à jour: ",
    noSurveyCreated: "No Survey Created",
    responseRateNoSurveyCreateMessage: [
        "Reporting data will be available once a survey has been created.",
        "Please contact your administrator if you have any questions."
    ],
    snapshot: "Snapshot",
    overallScores: "Notes globale",
    snapshotReport: "Snapshot Rapport",

    close: "Fermer",
    next: "Suivant",
    back: "Précédent",
    finish: "Terminer",
    reset: "Réinitialiser",
    hierarchyName: `Nom de l'organigramme`,
    createHierarchy: "Créer un organigramme",
    createPowerPoint: "Créer PowerPoint",
    configureCard: "Configurer une Carte",
    existingHierarchies: "Organigrammes existants",
    availableFields: "Champs Disponibles",
    hierarchyFields: `Champs de l'organigramme`,
    dragHereToAdd: "Glisser ici pour ajouter",
    fields: "Champs.",
    stackedBarCharts: "stacked bar charts.",
    responseCount: "Nombre de Réponses",
    childCount: `Nombre d'enfants`,
    exploreComments: "Explorer les Commentaires",
    showCorrelations: "Montrer Corrélations",
    collaborator: "Collaborateur",
    creator: "Créateur",
    info: "Info",
    howTo: "Comment",
    tasks: "Tâches",
    assign: "Assigner",
    status: "Statut",
    title: "Titre",
    dueDate: `Date d'échéance`,
    goals: "Objectifs",
    notes: "Remarques",
    attachments: "Les pièces jointes",
    dropFile: "Déposer le fichier ici ...",
    selectFile: "Glissez-déposez le fichier ici ou cliquez pour sélectionner le fichier",
    noActionAssociatedToCard: `Aucune Action n'est associée à cette Carte`,
    noActionSelected: `Aucune Action n'est créee`,
    surveyInformation: `Information sur le sondage`,
    organization: `Organisation`,
    organizationTimeZone: `Zone Horaire`,
    surveySections: `Sections du Sondage`,
    commentsEmptyMessageAdmin: "Sélectionnez une section pour afficher le nuage de mots et les commentaires.",
    commentsEmptyMessageManager: "Sélectionnez la section pour voir le nuage de mots.",
    noTaskToAction: `Aucune Action n'est associée à cette Carte`,
    deleteThisItem: `Supprimer cet élément?`,
    deleteCannotBeReversed: `Cette action ne peut pas être inversée et pourrait affecter d'autres utilisateurs.`,
    makeDefault: `Définir par défaut`,
    keyDriversNoDisplay: `Key drivers cannot be displayed with less than 30 responses.`,
    yes: `Oui`,
    no: "Non",
    reassignUser: `L'utilisateur est actuellement assigné à une différente carte et continuer cette action va le réassigner a cette carte.`,
    highlightUnassignedCards: `Montrer les cartes non assignées`,
    language: `Langue`,
    show: `Montrer`,
    peers: `les pairs`,
    parent: `les parents`,
    selectAll: `Sélectionner Tout`,
    deselectAll: `Déselectionner Tout`,
    new: `Nouvelle`,
    action: `Action`,
    task: `Tâche`,
    notStarted: `Pas Commencée`,
    inProgress: `En Cours`,
    complete: `Achevée`,
    default: `Défaut`,
    createHierarchyHelp: [
        `Faites glisser l'élément des champs disponibles vers les champs de l'organigramme pour créer organigramme.`,
        `Pour retirer un élément de la carte, faites-le glisser des champs de organigramme.`
    ],
    configureCardHelp: [
        `Faites glisser les éléments de la colonne de gauche vers la colonne de droite pour créer la présentation de carte par défaut.`,
        `Pour retirer un élément de la carte, faites-le glisser dans la colonne de gauche.`,
        `vue de la carte par défaut: vue de la carte qui sera utilisée et vue par toute personne se connectant à TalentGate pour la première fois.`,
        `Définissez la mise en page actuelle de votre carte comme configuration par défaut en cliquant sur l’icône située dans le coin supérieur droit, puis sur "Définir par défaut".`
    ],
    notAssigned: `Non attribué`,
    duplicatedHierarchyName: `Il existe déjà un organigramme avec ce nom.`,
    duplicatedActionName: `Il y a déjà une action avec ce nom.`,
    duplicatedTaskName: `Il existe déjà une tâche portant ce nom.`,
    setAsDefault: `Définir par défaut`,
    revertToDefault: `Rétablir la valeur par défaut`,
    clearCard: `Effacer la carte`,
    more: `Plus`,
    defaultCardLayoutNotSet: `La presentation de la carte par défaut n'est pas définie`,
    hierarchyOutOfDate: `L'organigramme est obsolète`,
    currentHierarchyOutOfDate: `L'organigramme actuelle est obsolète`,
    keyDriver: `Facteur clé`,
    keyDrivers: `Facteurs Clés`,
    allStepsCompleted: `Toutes les étapes terminées`,
    correlations: `Corrélations`,
    topCorrelations: `Top corrélations`,
    comments: `Commentaires`,
    commentsCaps: `COMMENTAIRES`,
    wordCloud: `Nuage de Mots`,
    wordCloudCaps: `NUAGE DE MOTS`,
    description: `La description`,
    noTaskDescription: `Il n'y a pas de description pour cette tâche`,
    customScales: `Custom Scales`,
    commentsDialogAdminHelp: [
        `La recherche affichera les commentaires qui correspondent`,
        `au mot ou des mots entrés.`,
        ` `,
        `En conséquence, le mot nuage affichera les mots le plus`,
        `utilisés dans les résultats de la recherche.`
    ],
    commentsDialogManagerHelp: [
        `Cela cherchera des commentaires pour le mot ou les mots`,
        `entré. Cela va changer le nuage de mots et va`,
        `afficher les mots les plus utilisés à partir `,
        `des commentaires qui sont associés à la recherche.`
    ],

    noStatusShown: `Aucun statut à afficher pour cette action.`,
    managerComparisons: `Comparaisons des managers`,
    immediateSuperior: `Supérieur immédiat`,
    notificationSettings: `Paramètres de notification`,
    turnOnNotification: `Activer les notifications`,
    managerComparisonsHelp: `Les gestionnaires peuvent uniquement voir la section de l'organigramme qui leur est affectée. 
        Cela leur permet de voir les autres cartes dans l'organigramme pour leur permettre de se comparer à l'ensemble de l'organisation, à leur supérieur immédiat et à leurs pairs au sein de leur équipe.`,
    notificationSettingsHelp: [
        `Les e-mails de notification ne sont envoyés aux utilisateurs que lorsqu'ils reçoivent une nouvelle notification.`,
        `Les nouvelles notifications comprennent:`,
        `* Affectation à une carte`,
        `* L'action est partagée sur une carte qui vous est attribuée`
    ],
    notificationWarming: `Un email de notification sera envoyé tous les jours à 9h00 AM (heure normale de l'Est)`,
    dueDateExpired: `Date d'échéance expirée`,
    correlationHelp: [
        `Une corrélation est une mesure statistique de la force de la relation entre deux dimensions`,
        ` `,
        `Plus la couleur du carré est sombre, plus la corrélation entre ces deux dimensions est forte`
    ],
    actionReport: `Rapport d'action`,
    noDefaultScale: "Il n'y a pas d'échelle par défaut",
    noQuestionToCustomScale: "Il n'y a pas de questions pour personnaliser l'échelle",
    noScaleNorQuestionSelected: `Il n'y a pas d'échelle ni de question sélectionnée`,
    cantDropFile: `Ce type de fichier ne peut pas être déposé ici`,
    fileNotUploaded: `Le fichier n'a pas été téléchargé, veuillez réessayer.`,
    files: `Fichiers`,
    groupStatus: `Statut du groupe`,
    cardStatus: `Statut de la carte`,
    personal: "Personal",
    shared: "Partagées",
    cannotSaveNoValidFilter: "Impossible d'enregistrer si le filtre n'est pas valided",
    duplicatedFilterName: `Il existe déjà un filtre portant ce nom.`,
    ignoreQuestion: "Ignorer la question",
    hideForManager: "Masquer pour les gestionnaires",
    noFilesEmptyStateMessage: `Aucun fichier joint.`,
    turnOnReportFilters: `Activer les filtres de rapport pour les gestionnaires`,
    clearFilterCondition: "Effacer les conditions du filtre",
    loadFilter: "Télécharger le filtre",
    addNewCondition: "Ajouter une nouvelle condition",
    clearCondition: "Effacer la Condition",
    doYouWantToProceed: "Voulez-vous continuer?",
    thisActionWillChangeLayout: "Cette action mettra à jour la mise en page des organigrammes existantes",
    benchmarkMapping: "Cartographie de référence",
    selectBenchmarkSection: "Sélectionner une section",
    selectBenchmarkQuestion: "Sélectionner une question",
    selectFieldsFilter: "Sélectionner tous les champs qui doivent figurer dans les filtres.",
    hiddenQuestionWillRemovedDefaultLayout:
        "Masquer la question pour les Managers mettra à jour la vue par défaut. Si vous utilisez la vue par défaut, elle mettra à jour votre graphique.",
    cannotSetDefaultLayoutWithHiddenQuestion:
        "La vue par défaut ne peut pas être définie avec une question masquée pour les managers",
    cannotSetDefaultLayoutWithHiddenSection:
        "La vue par défaut ne peut pas être définie lorsque toutes les questions d'une section sont ignorées ou masquées pour les managers",
    displayKeyDriversIsSetOff:
        "Afficher les facteurs clés pour les managers est désactivé dans les paramètres généraux",
    keyDriverCannotBePartDefaultLayoutEngagementHidden:
        "Les facteurs clés ne peuvent pas faire partie de la disposition par défaut si la section ENGAGEMENT est masquée ou ignorée",
    warning: "Avertissement",
    reportFilters: "Filtres du rapport",

    displayKeyDriversForManagers: "Display Key Drivers for Managers",
    keyDriversWillBeRemovedDefaultLayout: "Key Drivers will be removed from default layout",
    noGoals: "Aucun Objectif n'a été inscrit",
    disallowedFilterParagraphs: [
        "Les filtres sélectionnés produisent un rapport différent du même nombre ou moins de réponses que le seuil de confidentialité défini à partir d'un rapport récent. Pour préserver la confidentialité des réponses, nous ne sommes pas en mesure d'afficher ce rapport.",
        "Pour préserver la confidentialité des réponses individuelles, TalentMap garde une trace des résultats des rapports instantanés exécutés par chaque utilisateur. Si l'ensemble de personnes d'un rapport donné est trop similaire à l'ensemble de personnes d'un rapport récent, il peut devenir possible de voir si une personne donnée a répondu positivement ou négativement à un sondage. Lorsque cela se produit, nous empêcherons l'affichage de l'instantané afin de garantir la confidentialité de ces répondants. ",
        "Par exemple, filtrer les résultats pour ne voir que les résultats des hommes ou des femmes est généralement une chose légitime à faire car ils auront souvent des réponses différentes sur des choses comme l'équilibre travail / vie privée. Mais si un département spécifique ne compte qu'une seule femme, et les résultats sont filtrés pour n'inclure que des hommes, alors l'effet est de supprimer une seule personne du rapport de capture instantanée. Dans ce cas, vous pouvez facilement identifier la personne qui a été supprimée, et si les résultats deviennent plus positifs, vous savez alors que elle a répondu par négativement au sondage. ",
        "Le système garde la trace des cinq derniers rapports exécutés par chaque utilisateur, et le résultat de chaque nouveau filtre doit différer d'au moins le nombre seuil de confidentialité défini de répondants de l'un de ces rapports récents pour qu'il soit renvoyé. "
    ],
    noDescription: "Pas de Description",
    infoNotes: "Remarques",
    noOverallAvailable: "Pas de score global",
    displayBenchmarksInCard: "Afficher les donnés du Benchmark dans la Carte",
    cantDisplayBenchmark:
        "Donnés Benchmark ne peuvent pas être affichées tant que tous les éléments ne sont pas mappés",
    exportingSnapshotPowerPoint: "Exportation du rapport instantanné PowerPoint",
    thereIsNoFilterSaved: "Pas de Filtres sauvegardés",
    editHierarchyName: "Modifier le Nom de l'organigramme",
    actionPlanningTemplate: "Action Planning Template",
    editCardName: "Modifier le nom de la Carte",
    cardName: "Nom de la Carte",
    taskName: "Nom de la Tâche",
    filterIsNotValid: "Au moins un filtre qui n'est pas valide",
    order: "Ordre",
    published: "Publié",
    keyDriverHelp:
        "Les facteurs clés sont les domaines que l'analyse a identifiés comme les domaines ayant le plus grand impact sur l'engagement.",
    slide: {
        snapshotReport: {
            infoTitle: "Comprendre votre rapport",
            infoText: {
                first: "Sauf indication contraire, le score global de la dimension est la moyenne de tous les éléments, à l'exclusion des éléments personnalisés.",
                second: "Le % du score favorable de votre organisation est supérieur (+) ou inférieur (-) au % du score favorable du benchmark pour cet élément.",
                third: "Indique que du benchmark de l'élément n'est pas disponible pour la comparaison.",
                fourth: "Le% de score favorable de la période actuelle de votre organisation est supérieur (+) ou inférieur (-) au % de score favorable de la période précédente de votre organisation pour cet élément."
            },
            overallTitle: "Score Global",
            filterTitle: "Filtres de rapport appliqués",
            endTitle: "Notre confidentialité",
            disclaimer: "Aucune donnée ou rapport ne sera fournie à moins qu'il y ait au moins cinq (5) répondants"
        }
    },
    organizationLogo: "Logo de l'organisation",
    dialogUploadLogo: {
        controls: {
            labelUploadLogo: "Télécharger le logo",
            helperUploadFileType: "PNG ou SVG avec un fond transparent",
            helperUploadNoLogo: "Aucun logo téléchargé"
        },
        actions: {
            deleteLogo: "Supprimer le logo"
        }
    },
    exportPowerPoint: "Exporter vers PowerPoint",
    dialogExportSlide: {
        title: "Exporter vers PowerPoint",
        controls: {
            labelMainTitle: "Titre",
            labelSubTitle: "Sous-titre",
            helperMainTitle: "Apparaît sur la page titre et comme en-têtes de page du rapport",
            helperSubTitle: "N'apparaîtra que sur la page titre",
            errorMainTitle: "Champ obligatoire"
        }
    },
    errorMessage: {
        fileErrorNoMultiFiles: "Seulement un fichier est autorisé",
        fileErrorFormatPNGorSVG: "Le fichier doit être l'un des suivants: PNG ou SVG"
    },
    thankYou: "Merci",
    layoutCardLimitDrop: `La carte peut avoir jusqu'à 7 graphiques à barres (y compris le pilote clé).`,
    dragChartHere: `Faites glisser ici pour ajouter un pilote clé ou un graphique à barres`,
    dragResponseRateHere: `Le taux de réponse`,
    responseNATooltip:
        "Pour protéger la confidentialité, lorsqu'il y a moins de 5 réponses, nous masquerons les données. ",
    errorLoadingOrgChart:
        "Une erreur s'est produite lors du chargement de l'organigramme. Veuillez recharger la page ou sélectionner un autre organigramme.",
    engagement: "engagement",
    noSurveyResultSelected: "Aucun résultat du sondage sélectionné",
    noSurveyResultSelectedMsg:
        "Pour afficher l'organigramme, sélectionnez un résultat de sondage dans la liste déroulante en haut à gauche de la page",
    openMenu: "Ouvrir le menu",
    exportToPdf: "Export to PDF",
    exportToExcel: "Exporter vers Excel",
    heatmap: "Heatmap",
    unableToFetchPDF: "Impossible de récupérer le PDF",
    unableToFetchPPTX: "Impossible de récupérer le PowerPoint",
    heatmapReport: "Heatmap Rapport",
    compareTo: "Comparer aux",
    cellContent: "Contenu de la cellule",
    noDemographicSelected: "Aucune démographie sélectionnée",
    noDemographicSelectedMsg:
        "Pour afficher la carte thermique, veuillez sélectionner un groupe démographique dans la liste déroulante à gauche.",
    difference: "Différence",
    overall: "Organisation Globale",
    overallFiltered: "Résultats Filtrés",
    noMeaningfulInfo: "Nous ne pouvons pas vous montrer quelque chose de significatif ici.",
    noDemographicInfo: "Il n'y a aucune information démographique dans l'enquête sélectionnée.",
    noDemographicInfoMeg:
        "La carte thermique n'est pas disponible pour cette enquête, veuillez sélectionner une autre enquête.",
    item: "Item",
    demographicValue: "Valeur démographique",
    snapshotExportMsg: [
        "Exportation du fichier .pptx...",
        "L'exportation du fichier .pptx est terminée, veuillez vérifier votre dossier de téléchargement."
    ],
    comparisons: "Comparaisons",
    theAbilitytoCompareToOverall:
        "La capacité de se comparer à l'ensemble de l'organisation n'est disponible que lorsque le rapport est filtré.",
    compareGroup: "Groupe de comparaison",
    heatmapMesg: "Les données sont arrondies au nombre entier le plus proche.",
    snapshotCompareConfidentialityMsg: "Le groupe auquel vous comparez est en dessous du seuil de confidentialité.",
    switchAxis: "Changer d'axe",
    heatmapWarningMesg:
        "Les groupes inférieurs au seuil minimum ne sont pas affichés. Voir le rapport sur le taux de réponse pour plus de détails.",
    between: "compris entre",
    commentReport: "Commentaire Signaler",
    totalComments: "Total des réponses",
    resultsTooSimilar: "Résultats trop similaires",
    clearAll: "TOUT EFFACER",
    addLookdown: "Ajouter une ou plusieurs diapositives de recherche",
    lookdownTooltip:
        "Les diapositives Lookdown comparent les sous-groupes au groupe global. Ces diapositives sont placées au-dessus de la diapositive Scores globaux des dimensions dans l'ordre dans lequel elles sont créées.",
    clickThePlusButtonToAddANewLookdown: "Cliquez sur le bouton plus pour ajouter une diapositive de recherche",
    surveyDimensionOrItem: "Dimension ou élément d'enquête",
    allComments: "Tous les commentaires",
    demographicsLabels: "Étiquettes démographiques",
    originalDemographicsLabel: "Étiquette d'origine",
    demographicsLabelDataExample: "Exemple de données",
    demographicsReportLabels: "Libellé du rapport",
    demographicLabelTooltip:
        "Les étiquettes de rapport apparaîtront dans les filtres, les comparaisons, les cartes thermiques, les exportations de rapport, la cartographie de la période précédente et la boîte de dialogue d'exportation d'instantané.",
    reportLabelMustBeUnique: "Le libellé du rapport existe déjà. Veuillez mettre à jour.",
    reportLabelCannotBeBlank: "Le libellé du rapport ne peut pas être vide.",
    loading: "Chargement en cours...",
    clickThePlusButtonToAddANewHeatmapLookdown: "Cliquez sur le bouton plus pour ajouter une diapositive Heatmap",
    addHeatmapLookdown: "Ajouter des diapositives Heatmap",
    heatmapWarningPrefix: "AVERTISSEMENT DE DIAPOSITIVE HEATMAP",
    heatmapWarningSuffix: "a trop de valeurs sélectionnées et peut se développer hors de la diapositive",
    columnLabel: "Étiquette de colonne",
    columnLabelHelperText: "6 caractères maximum",
    columnLabelErrorText: "Limiter le libellé à 6 caractères",
    value: "Évaluer",
    scales: "BALANCE",
    fieldsTab: "DES CHAMPS",
    convertSurveyItemHelper:
        "Convertissez un champ d'élément d'enquête (question) en un champ démographique qui peut être filtré dans les rapports Snapshot, Heatmap et Commentaire.",
    reportLabel: "Libellé du rapport",
    addToDemographics: "Ajouter aux données démographiques",
    english: "Anglais",
    french: "Français",
    spanish: "Español",
    portuguese: "Portugais",
    addResponseRateLookdown: "Ajouter des diapositives de taux de réponse",
    clickThePlusButtonToAddANewRRLookdown: "Cliquez sur le bouton plus pour ajouter une diapositive Taux de réponse",
    properties: "Propriétés",
    showInFirstReportColumn: "Afficher dans la première colonne du rapport",
    addSecondMapping: "Ajouter une deuxième période",
    labelExists: "L'étiquette de mappage existe",
    addKeyDriversLookdown: "Diapositive Ajouter des pilotes clés",
    keyDriverslookdownTooltip:
        "Les facteurs clés ne peuvent être générés que s'il y a 30 répondants ou plus à l'enquête. Les principaux moteurs apparaîtront sur une seule diapositive. Ajoutez un maximum de 3 (trois) pilotes clés.",
    clickThePlusButtonToAddANewKeyDriversLookdown:
        "Cliquez sur le bouton plus pour ajouter une diapositive Key Drivers",
    responseCountWarning:
        "Le nombre minimum de répondants requis pour générer une analyse des facteurs clés est de 30.",
    columnLabelHelperText24: "30 caractères maximum",
    columnLabelErrorText24: "Limiter le libellé à 30 caractères",
    noItemAvailable: "Aucun score d'élément disponible",
    correlationReport: "Corrélations Signaler",
    correlationHelpText: [
        `Une corrélation est une mesure statistique de la force de la relation entre deux dimensions`,
        ` `,
        `ou éléments. Plus la couleur du carré est foncée, plus la corrélation est forte.`
    ],
    notEnoughCorrelationResponse: "L'exécution d'un rapport de corrélation nécessite au moins 30 réponses ou plus.",
    benchmarkTags: "Balises de référence",
    addTag: "Ajouter une étiquette",
    deleteTag: "Supprimer la balise",
    createNewTag: "Créer une nouvelle balise",
    thisActionWillPermanentlyRemoveTag:
        "Cette action est irréversible. La balise sera supprimée de toutes les organisations, enquêtes et analyses comparatives créées précédemment. Les données des points de repère déjà créés ne seront pas modifiées.",
    createdTags: "Balises créées",
    duplicateTag: "La balise existe déjà.",
    benchmarkInformation: "Balises de référence",
    createNewBenchmark: "Créer une nouvelle référence",
    englishTitle: "Titre anglais",
    frenchTitle: "Titre français",
    numberOfOrganizations: "# d'organisations",
    numberOfSurveys: "# d'enquêtes",
    numberOfResponses: "# de réponses",
    archived: "archivé",
    activate: "Activer",
    archive: "archive",
    duplicateBenchmarkName: "Nom de référence en double",
    hideBenchmarkData: "Masquer les données de référence",
    hidePPData: "Masquer les données de la période précédente",
    removeFilterSimilarityWarning: "Supprimer l'avertissement de similarité du filtre",
    canViewAllOrganizationReportDataTooltip:
        "Cocher cette case permettra à l'utilisateur de voir toutes les données du rapport pour chaque enquête. Si vous ne la cochez pas, vous pourrez affecter l'utilisateur à une carte dans l'organigramme. Pour en savoir plus, cliquez sur le point d'interrogation.",
    removeFilterSimilarityWarningTooltip:
        "Cocher cette case permettra à l'utilisateur d'exécuter des filtres sur de petits groupes démographiques. Pour en savoir plus, cliquez sur le point d'interrogation.",
    clearBenchmarkMappingTitle: "Effacer la cartographie des points de repère?",
    clearBenchmarkMapping: "Effacer le benchmark supprimera toutes les données de benchmark de ce résultat d'enquête.",
    dimensionsAndItems: "Dimensions & Articles",
    commentCount: "Compteur de commentaire",
    wordCloudSettings: "Paramètres du nuage de mots",
    ok: "d'accord",
    number: "le nombre",
    showTopWordsPrefix: "Afficher le haut",
    showTopWordsSuffix: "mots",
    invalidTopWordsNumber: "Numéro invalide",
    maximumTopWordNumber: "Maximum 50",
    importStopWords: "Fichier de Mots Vides (.csv)",
    stopWordsTooltip:
        "Importez des mots vides avec chaque mot dans une seule ligne à l'aide d'un fichier .csv. Pour en savoir plus, cliquez sur le point d'interrogation.",
    stopWordsErrorMustUploadCSV: "Veuillez télécharger un fichier CSV",
    stopWords: "Mots vides",
    stopWordExist: "Le mot vide existe déjà",
    duplicateStopWords: "Mots vides en double dans le fichier csv",
    resetWordCloudTitle: "Réinitialiser le nuage de mots?",
    resetWordCloudMessage: "Cette action est irréversible et peut affecter d'autres utilisateurs.",
    commentSettings: "Paramètres de Commentaire",
    preferences: "Préférences",
    hiddenWordsAndPhrases: "Mots / phrases cachés",
    showCommentMinWordsPrefix: "Afficher au moins",
    showCommentMinWordsSuffix: "mots",
    importHiddenWords: "Mots/Phrases Cachés Fichier (.csv)",
    hiddenWordsTooltip:
        "Importez des mots, des noms, des phrases, etc. avec chaque mot/phrase dans une seule ligne à l'aide d'un fichier .csv. Pour en savoir plus, cliquez sur le point d'interrogation.",
    hiddenWordExist: "Le mot/phrase caché existe déjà",
    resetHiddenWordTitle: "Réinitialiser les mots cachés?",
    showCommentRandomSamplePrefix: "Afficher un échantillon de",
    showCommentRandomSampleSuffix: "commentaires par article",
    minmumTopWordNumber: "Minimum 1",
    commentSamplePrefix: "Exemple de Commentaire - montrant",
    of: "de",
    commentSampleSuffix: "nombre total de commentaires",
    words: "mots",
    minimum: "le minimum",
    randomSample: "Échantillon aléatoire",
    commentsLowerCase: "commentaires",
    maximumOneWord: "Maximum 1 mot",
    dimensionScore: "Score de dimension",
    dimsionScoreThreshold: "Seuil 1-5. Pas plus de 0,2 décimale",
    maximumFive: "Maximum 5",
    minimumOne: "Minimum 1",
    invalidNumber: "Numéro invalide",
    showDimensionScore: "Afficher le score de dimension",
    dimensionScoreHighToLow: "Dimension - du plus élevé au plus bas",
    dimensionScoreLowToHigh: "Dimension - du plus bas au plus élevé",
    noneCapitalize: "Aucune",
    sort: "Trier",
    dimsionScoreFavorable: "Favorable ≥ à",
    dimsionScoreUnfavorable: "Défavorable ≤ que",
    dimensionScoreTooltip:
        "Définissez un seuil pour afficher les résultats par score de dimension. Les scores supérieurs à / inférieurs à ne peuvent pas se chevaucher. Pour en savoir plus, cliquez sur le point d'interrogation.",
    dimsionScoreCommentSettingsThreshold:
        "Modifier le seuil des points colorés par dimension. Les scores supérieurs à / inférieurs à ne peuvent pas se chevaucher. Pour en savoir plus, cliquez sur le point d'interrogation.",
    dimsionColorThreshold: "Seuil de couleur de dimension",
    showSentimentScore: "Faire preuve de confiance",
    sentimentScoreHighToLow: "Le sentiment - du plus élevé au plus bas",
    sentimentScoreLowToHigh: "Le sentiment - du plus bas au plus élevé",
    sentimentScoreCalculationInProgress: "Susciter la confiance des commentaires. Cela peut prendre plusieurs minutes.",
    negativeSentiment: "Sentiment négatif",
    positiveSentiment: "Sentiment positif",
    neutralSentiment: "Sentiment neutre",
    score: "confiance",
    overallSentiment: "Sentiment Général",
    positive: "Positif",
    negative: "Négative",
    sentimentScore: "Confiance en soi",
    autoTranslate: "Traduction automatique",
    sentimentScoreHeader: "Plage de Pourcentage",
    sentimentScoreTooltip:
        "Définir la plage de pourcentage de confiance du sentiment. Pour en savoir plus, cliquez sur le point d'interrogation.",
    passwordReset: "Courriel envoyé avec succès.",
    passwordResetError: "Erreur de réinitialisation du mot de passe.",
    resetPassWord: "Réinitialiser le mot de",
    assignAccess: "Attribuer un Accès",
    assignRestrictedReportViewers: "Attribuer des visualiseurs de rapports restreints",
    noRestrictedUser: "Aucune Visionneuse de Rapport Restreint Créée",
    filtersUpperCase: "FILTRES",
    filterLabel: "Libellé du Filtre",
    applyFilter: "Appliquer le Filtre",
    autoTranslationCalculationInProgress: "Traduction automatique en cours. Cela peut prendre plusieurs minutes.",
    addusersToStart: "Pour ajouter un utilisateur, allez dans Admin puis Utilisateurs",
    noReportDataAvailable: "Aucune Donnée de Rapport Disponible",
    pleaseContactAdministrator: [
        "Aucun rapport ne vous a encore été attribué.",
        "Veuillez contacter votre administrateur pour obtenir de l'aide."
    ],
    dashboardLayoutOptions: "Concepteur de Tableau de Bord",
    dashboardTooltip:
        "Ajoutez des types de rapport à l'éditeur de mise en page en cliquant sur une icône en forme de crayon. Pour en savoir plus, cliquez sur le point d'interrogation.",
    preview: "Aperçu",
    configurePane: "Configurer le Volet du Tableau de Bord",
    image: "Image",
    pane: "Vitre",
    addPNGImage: "Ajouter une Image PNG",
    deleteDashboardConfiguration: "Effacer la configuration du tableau de bord?",
    dashboardTitle: "Titre du Tableau de Bord",
    spanishTitle: "Titre Espagnol",
    singleDimensionOrItem: "Dimension ou Élément Unique",
    label: "L'étiquette",
    columnLabelErrorText50: "Limiter le libellé à 50 caractères",
    columnLabelHelperText50: "50 caractères maximum",
    singleScore: "Score Unique",
    beingUsed: "utilisé",
    noDashboardcreated: "Aucun tableau de bord n'a été créé pour ce rapport",
    column: "Colonne",
    row: "Rangée",
    size: "Taille",
    goToSnapshotReport: "Aller au rapport instantané",
    overallDimension: "Dimension Globale",
    hidePriveousPeriodData: "Masquer les données de la période précédente",
    keyDriverOf: "Facteurs Clés",
    dimension: "Dimension",
    impact: "Incidence",
    belowKeyDriverThreshold: `Le nombre minimal de répondants requis pour produire l'analyse des facteurs clés est de 30.`,
    review: "examen",
    singleDimensionWithItems: "Dimension avec Éléments",
    lookdown: "Lookdown",
    excludedDimensions: "Dimensions Exclues",
    overallDimensionScore: "Scores de dimension globale",
    bm: "BM",
    map: "Orienté",
    permissions: "Autorisations",
    bulkExport: "Exportation Massive",
    isBulkReportingEnabled: "Produire des rapports en bloc",
    dashboardOverall: "Globale",
    count: "Compte",
    tooManyRowsWarning: "Il y a trop de lignes de données pour afficher correctement ce tableau.",
    bulkExportPowerPoint: "Exportation en vrac vers PowerPoint",
    bulkStatus: "État Bulk",
    bulkProgress: "État d'avancement des exportations du rapport en vrac",
    startSnapshotBulkExport:
        "Rapport Snapshot en vrac mis en file d'attente. Vérifier l'état dans la page des téléchargements.",
    reportQueued: "Rapport mis en attente",
    queuePositions: "File d'attente",
    reportGenerating: "Générer des rapports",
    reportCompleted: "Rapport complet",
    report: "Rapport",
    showQuestionnaireItemOnly: "Afficher les éléments du questionnaire seulement",
    startDownloading: "Rapport de téléchargement",
    favourableUnfavourableItems: "Favourable / Unfavourable Items",
    bulkReportDemographics: "Rapport Démographique",
    startHeatmapBulkExport:
        "Rapport heatmap en vrac mis en file d'attente. Vérifier l'état dans la page des téléchargements.",
    startCommentBulkExport:
        "Rapport de commentaires en vrac mis en file d'attente. Vérifier l'état dans la page des téléchargements.",
    startCorrelationBulkExport:
        "Rapport corrélation en vrac mis en file d'attente. Vérifier l'état dans la page des téléchargements.",
    startDashboardBulkExport:
        "Rapport tableau de bord en vrac mis en file d'attente. Vérifier l'état dans la page des téléchargements.",
    bulk: "Gros",
    bulkExportExcel: "Exportation en vrac vers Excel",
    bulkExportPDF: "Exportation en vrac vers PDF",
    queued: "En File D'attente",
    errored: "Erroné",
    completed: "Complété",
    noQueuedBulkExports: "Aucune exportation en vrac en attente",
    noErroredBulkExports: "Aucune exportation en vrac erronée",
    noCompletedBulkExports: "Aucune exportation en vrac terminée",
    deleteBulkExport: "Supprimer l'exportation en vrac",
    deleteCannotBeReversedForBulk: "Cette action ne peut pas être inversée.",
    deleteAllBulkExport: "Supprimer toutes les exportations en vrac terminées",
    deleteCompleted: "Supprimer terminé",
    requestedTime: "Heure Demandée",
    completedTime: "Temps Révolu",
    errorInfo: "Renseignements sur L'erreur",
    overallSS: "Global",
    removeOverallComment: "Supprimer la Diapositive Commentaires Généraux",
    insights: "Informations",
    outcome: "Résultat",
    keyItems: "Éléments Clés",
    keyItemBelow30: "Ce rapport nécessite au moins 30 réponses.",
    percentage: "pourcentage",
    showInHeatmapComparison: "Afficher dans Heatmap Comparaisons",
    isInsightPageEnabled: "Consulter des Page Insights",
    commentSample: "Commentaires relatifs à ce point",
    loadingComments: "Chargement des commentaires. Cela peut prendre plusieurs minutes.",
    noCommentInInsightPage: "Ce sondage ne contient aucun commentaire textuel.",
    participants: "Participants",
    open: "Ouvrir",
    closed: "Fermé",
    noOpenSurvey: "Il n'y a pas de sondages ouverts dans votre compte",
    noClosedSurvey: "Il n'y a pas de sondages fermés dans votre compte",
    uploadCSVFile: "Importer un Fichier CSV",
    statusReport: "Rapport de Situation",
    NumberOfParticipantsUpdated: "Nombre de Participants mis à jour",
    missingColumnHeader: "En-tête(s) de Colonne Manquant(s)",
    invalidEmails: "Courriel non Valide",
    copyToClipborad: "copier dans le presse-papiers",
    missingEmailField: "Champ de Courriel Manquant",
    missingEmailValue: "Il y a une cellule vide dans la colonne email",
    clickImportToAddContacts: "Cliquez sur Importer pour ajouter des participants",
    page: "Page",
    confirmDelete: "Confirmer la Suppression",
    deletingParticipant: "La suppression d'un participant ne peut pas être annulée.",
    uploadStatus: "État de Chargement",
    loadingParticipants: "Chargement des participants",
    duplicateEmailField: "Champ duplicate email",
    uploadMayTakesTime: [
        "Le téléchargement peut prendre plusieurs minutes.",
        "Cliquez sur la page d'accueil pour continuer à utiliser Compass."
    ],
    activityLog: "Journal d'activité",
    createdTime: "Date de Création",
    lastResponseSubmittedTime: "Dernière Réponse Soumise",
    user: "Utilisateur",
    fieldAdded: "Champ démographique ajouté",
    fieldRemoved: "Champ démographique supprimé",
    participantCreated: "Participants ont Créé",
    participantUpdated: "Mise à jour des Participants",
    participantDeleted: "Participants Supprimés",
    noActivityLog: "Aucune activité de l'utilisateur",
    andMore: "et plus",
    initialImport: "Importation Initiale",
    saveSuccessfully: "Enregistrée avec Succès",
    isParticipantEnabled: "Afficher la page des participants",
    lockPPAndBenchmark: "Benchmark de verrouillage et cartographie de la période précédente",
    confirmImport: "Confirmer l'importation",
    confirmImportParticipants: [
        "Assurez-vous que le fichier est correct. Le téléchargement incorrect du fichier peut entraîner la perte des réponses au sondage.",
        "Si des modifications sont apportées à l'en-tête d'étiquette de colonne, les données de la période précédente devront être remappées pour la ou les colonnes concernées."
    ],
    lockMessagePrefix: "Veuillez communiquer avec votre gestionnaire de projet TalentMap ou à l'adresse ",
    lockMessageSuffix: " pour déverrouiller cette cartographie d'enquête.",
    opportunity: "Opportunité",
    strength: "Force",
    noneUpperCase: "Aucun",
    summary: "Résumé",
    commonTopicsFrom: "Sujets communs de",
    loadingCommentsSummary:
        "Chargement du résumé des commentaires et des suggestions. Cela peut prendre plusieurs minutes.",
    commonSuggestionFrom: "Suggestions possibles d'amélioration",
    uploadContactsError: "Une erreur est survenue lors du téléchargement des participants. Veuillez contacter ",
    summarySettings: "Résumé des paramètres",
    summaryPrompt: "Résumé Sujet",
    suggestionPrompt: "Suggestions Sujet",
    confirmEditPrompt: "Confirmer la modification de la Promp",
    editPromptMessage:
        "La modification d'une invite modifiera le résumé ou le texte des suggestions pour tous les utilisateurs qui ont accès aux commentaires.",
    aiInsights: "Informations sur l'IA",
    updateEmailField: "Le système n'autorisera pas les mises à jour de l'en-tête de colonne de courrier électronique",
    bulkPPT: "GROS PPT",
    bulkEXCEL: "GROS EXCEL",
    bulkPDF: "GROS PDF",
    bulkstatus: "GROS STATUT",
    powerpoint: "POWERPOINT",
    pdf: "PDF",
    anyWord: "Mot Cuelconque",
    exactMatch: "Texte Exact",
    semanticSearch: "Signification",
    ask: "DEMANDER",
    loadingCommentsAsk: "Générer une réponse...",
    commentAskAnswer: "Obtenez des réponses générées par l'IA aux questions sur ",
    typeYourQuestion: "Tapez votre question ici",
    confirmNewCommentConversation: "Tout le texte sera supprimé.",
    tour: "Visites",
    clear: "Clair",
    clearConversation: "Supprimer la conversation",
    benchmarkData: "Référence",
    pPData: "Période Précédente",
    other: "Autre",
    reportError: "Une erreur s'est produite",
    pleaseContact: "Veuillez contacter",
    group: "Groupe",
    siteAdmin: "Administrateur du Site",
    restrictedReportViewer: "Afficheur de Rapports Restreint",
    reportViewer: "Visionneuse de Rapports",
    never: "Jamais",
    lastAccess: "Dernier Accès",
    itemBelowConfidentialityThreshold: "L'article est inférieur au seuil de confidentialité",
    previousPeriodMappingExporting: "Exporter les données PreviousPeriod.xlsx...",
    previousPeriodMappingExportCompleted:
        "L'exportation du fichier Previous Period Export.xlsx est terminée, veuillez vérifier votre dossier de téléchargement.",
    knowledgeBase: "Base de Connaissances",
    whatIsNew: "Quoi de Neuf",
    duplicateHeader: "En-tête en double",
    clearDimensionMappings: "Effacer les mappages de dimensions ?",
    thisActionWillUnmapItemsMapping: "Cette action supprimera tous les éléments de la dimension.",
    lookup: "Recherche",
    addLookupLookdown: "Ajouter des diapositives de recherche",
    lookupLookdownTooltip:
        "La fonction de recherche vous permet d'isoler un groupe, puis de voir le score pour un élément ou une dimension en particulier et de choisir ce qui se trouve au-dessus et en dessous du groupe. Pour en savoir plus, cliquez sur le point d'interrogation.",
    includeLookupOrgOverall: "Inclure l'ensemble de l'organisation",
    includeWordCloud: "Inclure le mot de commentaire nuage(s)",
    downloads: "Téléchargements",
    bulkReportDownloads: "Téléchargements de rapports en bloc",
    bulkDemographicSame: "Les champs démographiques et de recherche en bloc ne peuvent pas être les mêmes.",
    selectOrg: "Sélectionner une organisation",
    noCreatedBulkExports: "Aucun rapport en vrac créé",
    resend: "Réenvoyer l'invitation",
    editParticipantsWarning:
        "Les changements à cette liste ne sont pas permis, car l'enquête est actuellement mappée à une autre enquête pour des données de période antérieure. Pour apporter des changements, veuillez d'abord démapper le sondage qui utilise cette liste de participants.",
    allowInReports: "Disponible pour les Rapports",
    orgEngagementScore: "Indice de mobilisation de l'organisation"
};
