import ConfigService from "./Config.service";

import { AdminService as IAdminService, ResponseDashboardData, ResponseDashboardPanel } from "./interfaces";
import { FilterParameter } from "components/filters/interfaces";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { PanelInput, TitleTranslation } from "components/admin/results/dashboard/interfaces";
import { ResponseRestrictedUserInfo } from "components/admin/results/assignAccess/interfaces";
import {
    AdminParticipantSurveyResponse,
    AdminParticiantStatusResponse,
    AdminParticipantsResponse,
    AdminParticipantUploadStatus,
    ActivityLogInfoResponse,
    AdminParticiantStatusErrorType
} from "components/admin/participants/interfaces";
export default class AdminService extends ConfigService implements IAdminService {
    resetPassword(userId: number): Promise<void> {
        const postUrl = this.makeUrl("/admin/resetpassword");
        return this.post(postUrl, { userId: userId });
    }
    getRestrictedUserList(surveyId: number): Promise<ResponseRestrictedUserInfo[]> {
        const params = {
            surveyId
        };

        const postUrl = this.makeUrl(`/RestrictedUserSurveyFilters`);
        return this.post(postUrl, params);
    }
    updateRestrictedUserList(surveyId: number, userId: number, filters: FilterParameter[]): Promise<void> {
        const options = {
            surveyId,
            userId,
            filters: filterConnectorToBackEnd(filters)
        };
        const postUrl = this.makeUrl("/RestrictedUserSurveyFilters/Update");
        return this.post(postUrl, options);
    }
    getDashboardPanel(surveyId: number): Promise<ResponseDashboardPanel> {
        return this.get(`/Dashboard/Layout?surveyId=${surveyId}`);
    }
    updateDashboardPanel(translations: TitleTranslation[], surveyId: number, items: PanelInput[]): Promise<unknown> {
        const params = {
            translations,
            surveyId,
            items
        };

        const postUrl = this.makeUrl(`/Dashboard/Layout`);
        return this.post(postUrl, params);
    }
    exportDashboardEditor(surveyId: number, languageCode: string, filters: FilterParameter[]): Promise<unknown> {
        const postUrl = this.makeUrl("/FileExport/Dashboard");
        const params = {
            surveyId,
            languageCode,
            filters: filterConnectorToBackEnd(filters)
        };
        return this.getBlobFile(postUrl, true, params);
    }
    getDashboardData(
        surveyId: number,
        languageCode: string,
        filters: FilterParameter[]
    ): Promise<ResponseDashboardData> {
        const options = {
            surveyId,
            languageCode,
            filters: filterConnectorToBackEnd(filters)
        };
        const postUrl = this.makeUrl("/Dashboard");
        return this.post(postUrl, options);
    }
    automapReview(surveyId: number, prevSurveyIds: number[]): Promise<unknown> {
        const params = {
            surveyId,
            prevSurveyIds
        };
        const postUrl = this.makeUrl("Admin/AutomapReview");
        return this.getBlobFile(postUrl, true, params);
    }
    getAdminSurveys(orgId: number, languageCode: string): Promise<AdminParticipantSurveyResponse[]> {
        return this.get(`/Admin/Surveys?organizationId=${orgId}&languageCode=${languageCode}`);
    }
    updateAdminParticipants(
        surveyId: number,
        csvFile: File,
        keyFieldName: string
    ): Promise<AdminParticiantStatusResponse> {
        const formData = new FormData();
        formData.append("surveyId", surveyId.toString());
        formData.append("csvFile", csvFile);
        formData.append("keyFieldName", keyFieldName);
        const postUrl = this.makeUrl("/Admin/Participants");
        return this.upload(postUrl, formData);
    }

    getAdminParticipants(surveyId: number): Promise<AdminParticipantsResponse> {
        return this.get(`/Admin/Participants?surveyId=${surveyId}`);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editAdminParticipants(participantValues: any[]): Promise<AdminParticiantStatusErrorType> {
        const options = {
            participantValues
        };
        const postUrl = this.makeUrl("Admin/EditParticipant");
        return this.post(postUrl, options);
    }
    deleteAdminParticipant(respondentId: number): Promise<void> {
        const options = {
            respondentId
        };
        const postUrl = this.makeUrl("Admin/DeleteParticipant");
        return this.post(postUrl, options);
    }
    getAdminParticipantUploadStatus(surveyId: number): Promise<AdminParticipantUploadStatus | null> {
        return this.get(`/Admin/ParticipantUploadStatus?surveyId=${surveyId}`);
    }
    getAdminParticipantActivityLog(surveyId: number): Promise<ActivityLogInfoResponse> {
        const options = {
            surveyId
        };
        const postUrl = this.makeUrl("Admin/ActivityLog");
        return this.post(postUrl, options);
    }
    clearDimensionSurveyComparison(surveyComparisonId: number, fieldIds: number[]): Promise<number[]> {
        const options = {
            surveyComparisonId,
            fieldIds
        };
        const postUrl = this.makeUrl("Admin/clearDimensionSurveyComparison");
        return this.post(postUrl, options);
    }
    deactivateAdminUser(userId: number): Promise<void> {
        const postUrl = this.makeUrl("/admin/deactivateUser");
        return this.post(postUrl, { userId: userId });
    }
}
