import { Translation } from "../interfaces";

export const englishUI: Translation = {
    dashboard: "Dashboard",
    surveys: "Surveys",
    reports: "Reports",
    feedback: "Feedback",
    actionPlanning: "Action Planning",
    admin: "Admin",
    profile: "Profile",
    settings: "Settings",
    help: "Help",
    logout: "Logout",
    home: "Home",
    powerPoint: "PowerPoint",
    excel: "Excel",
    export: "Export",
    live: "Live",
    apply: "apply",
    contactUs: "Contact Us",
    loginError: "Login Error",
    noOptionsAvailable: "No Options Available",
    loginErrorMsg: "Your account has been disabled. Please contact us at support@talentmap.com.",
    pageNotFound: "404 - Page Not Found",
    sorryCantSeemPage: "Sorry, we cannot seem to find what you are looking for.",
    pageNotExistOrRemoved: "The page either does not exist or has been removed",
    noSurveyInformationSelected: "No Survey Information Selected",
    selectSurveyInformation: "Select Survey Information",
    goHomepage: "go to homepage",
    goalsChallenges: "Goals and Challenges",
    organizations: "Organizations",
    users: "Users",
    responses: "Responses",
    adminSurveys: "Surveys",
    responseRateReport: "Response Rate Report",
    emptyData: "<Blank Value>",
    refreshPage: "Refresh the Page",
    internalServerError: "500 - Internal Server Error",
    somethingWentWrong: "Sorry, something went wrong",
    workingToFix: "We are working to fix the issue",
    addOrganization: "Add organization",
    import: "import",
    addUser: "Add user",
    information: "Information",
    tags: "Tags",
    deactivate: "deactivate",
    reactivate: "reactivate",
    name: "Name",
    yearEstablished: "Year Established",
    employeeCount: "Employee Count",
    industry: "Industry",
    type: "Type",
    logo: "Logo",
    uploadLogo: "Upload Logo",
    dragDropFilesHereOrClick: "Attach file here",
    dropHere: "Drop here",
    pngTransparent: "PNG with a transparent background",
    errorMustUploadPNG: "You must upload a PNG file",
    cannotBeDroppedHere: "This type of file cannot be dropped here",
    organizationHasBeenDeactivated: "Organization has been deactivated",
    organizationHasBeenReactivated: "Organization has been reactivated",
    general: "General",
    reportScales: "Report Scales",
    benchmarks: "Benchmarks",
    dimensions: "Dimensions",
    surveyNameCannotBeBlank: "Survey name cannot be blank",
    orgNameCannotBeBlank: "Organization name cannot be blank",
    cancel: "cancel",
    save: "save",
    organizationSaveSuccessfully: "Organization saved successfully",
    organizationInformationLabel: "Organization Information",
    noFileAttached: "No file attached",
    uploadSurvey: "Import Survey",
    upload: "Upload",
    noLogoUploaded: "No logo uploaded",
    deleteLogo: "Delete logo",
    numberOfRows: "Number of rows",
    goBack: "delete and start over",
    completeUpload: "Complete Upload",
    uploadSurveyPreview: "Import Preview",
    noOrganizationSelected: "No organization selected",
    deactivated: "deactivated",
    active: "active",
    selectBeforeManagingSurveys: "Select before managing surveys",
    errorLoadingContactUs: "An error occurred while loading. If this issue keeps occurring please contact us.",
    errorLoadingCSV: "Error loading CSV file",
    errorMustUploadCSV: "You must upload a CSV file",
    surveyFileCSV: "Survey File (.csv)",
    responseFileCSV: "Response File (.csv)",
    scaleFileCSV: "Scale File (.csv)",
    startDate: "Start date",
    endDate: "End date",
    closeDate: "Close date",
    required: "Required",
    surveyHasBeenDeactivated: "Survey has been deactivated",
    surveyHasBeenReactivated: "Survey has been reactivated",
    errorUpdatingContactUs: "An error occurred while updating. If this issue keeps occurring please contact us.",
    dateCreated: "Date Created",
    createdBy: "Created By",
    orgChart: "Org Chart",
    yearEstablishedMustBeNumber: "Year Established must be a number",
    yearEstablishedMustBe4Digits: "Year Established must be 4 digits (ex: 1983)",
    minimumYearEstablished: "Minimum year allowed is ",
    maximumYearEstablished: "Maximum year allowed is ",
    reportWillBeUnlockSurveyCreated: `Reports will be unlocked once a survey has been created.`,
    reportWillBeUnlockReportViewer: "Reports will be unlocked once a site admin has uploaded survey response data.",
    startSurveyClickSurveyOrUploadSurvey: `Upload a survey by clicking "Admin"`,
    email: "Email",
    isCommentViewer: "View Comments",
    canCreateSurveys: "Create Surveys",
    isSiteAdmin: "Site Admin",
    userInformationLabel: "User information",
    source: "Source",
    noActiveUser: "Add a user",
    inviteUser: "Users added to the organization will appear here",
    noDeactivatedUSer: "There are no deactivated users",
    usersRemovedFromOrg: "Users that are removed from the organization are placed here.",
    isSurveyCreator: "Survey Creator",
    talentMapAdmin: "TalentMap Admin",
    role: "Role",
    newUserHasBeenCreated: "New user has been created",
    userHasBeenDeactivated: "User has been deactivated",
    userHasBeenReactivated: "User has been reactivated",
    userNameCannotBeBlank: "User name cannot be blank",
    userEmailCannotBeBlank: "User email cannot be blank",
    userEmailCannotBeInvalid: "User email cannot be invalid",
    userEmailError: "This email address might have been used",
    userCreateError: "Creating user failed, please check your input or try again later",
    surveyNameMustBeUnique: "Survey name must be unique",
    duplicatedLiveSurveyName: "You have a live survey with duplicate names",
    unableSaveSurvey: "Unable to save the survey",
    organizationNameMustBeUnique: "Organization name must be unique",
    addSurveyResult: "Add Survey Result",
    campaign: "Campaign",
    campaigns: "Campaigns",
    resultOptimization: "Setup Survey Result",
    survey: "Survey",
    selectSurvey: "Please select a survey",
    campaignName: "Name",
    confirm: "Confirm",
    results: "Results",
    create: "Create",
    surveyGizmoLoadingError: "Unable to load surveys from Survey Gizmo, please try to refresh the page",
    noSGCampaigns: "No campaigns available",
    noSGSurveys: "No survey available",
    confidentialityThreshold: "Confidentiality Threshold",
    invalidConfidentialityThreshold: "Invalid Confidentiality Threshold",
    belowConfidentialityThreshold: "Below Confidentiality Threshold",
    surveyResultName: "Survey Result Name",
    compass: "Compass",
    imported: "Imported",
    noActiveOrganizations: "No active organizations",
    noActiveOrganizationsMessage: 'Click the "Add Organization" button at the top to add a new organization.',
    noDeactivatedOrganizations: "No deactivated organizations",
    noDeactivatedOrganizationsMessage: "Organizations that are deactivated are placed here.",
    noResultsCreated: "No results created",
    noResultsCreatedMessage: 'Click the "Add Survey Result" button at the top to add a new result.',
    noDeactivatedResults: "No deactivated results",
    noDeactivatedResultsMessage: "Results that are deactivated are placed here.",
    permission: "Permission",
    continue: "Continue",
    surveyResults: "Survey Results",
    search: "Search",
    switchOrganization: "Switch Organization",
    responseRate: "Response Rate",
    change: "change",
    reportBreakdown: "Report Breakdown",
    contactField: "Demographic",
    surveyItem: "Survey Item",
    overallResponseRate: "Overall Response Rate",
    surveysSent: "Sent",
    surveysCompleted: "Completed",
    responseRatePercentage: "Response Rate (%)",
    collapseAll: "Collapse all",
    expandAll: "Expand all",
    toSeeBreakdownReport: "To see a breakdown of the report select an item from the drop down to the left.",
    clickThePlusIconToAddMoreBreakdown: "Click the plus icon to add more items to the report breakdown.",
    clickThePlusButtonToAddANewFilter: "Click the plus button to add a new filter",
    automap: "Automap",
    selectBenchmark: "Select benchmark",
    canViewAllOrganizationReportData: "View All Organization Report Data",
    reportViewerWarning: "Selecting permission will unassign user from cards they may be assigned",
    none: "none",
    noOrgChartAvailable: "No Org Chart Available",
    noOrgChartAvailableMsg: "At this time you have not yet been assigned to a card in the org chart.",
    noOrgChartAvailableContact: "Please contact your administrator for assistance",
    filters: "Filters",
    filter: "Filter",
    equalTo: "Equal To",
    notEqualTo: "Not Equal To",
    greaterThan: "Greater Than",
    lessThan: "Less Than",
    and: "AND",
    or: "OR",
    date: "Date",
    showBenchmarkInReports: "Show Benchmark in Reports",
    confirmAutomapTitle: "Automap Benchmarks?",
    confirmAutomapMessage: "Automapping benchmarks will clear all benchmarks currently mapped.",
    benchmarkMappingHelp: [
        "Clicking the Automap button will automatically map benchmark items to items in your survey.",
        "Any items that could not be automatically mapped will be indicated with a red background.",
        "Unmapped items can be manually mapped by selecting the appropriate section and then item from the benchmark."
    ],
    benchmarkMappingEmptyState: [
        "To start select a benchmark type in the dropdown above.",
        "For help on how to do benchmark mapping refer to the help icon."
    ],
    noBenchmark: "No Benchmark",
    switchBenchmark: "Switch Benchmark?",
    switchBenchmarkMsg: "Switching the benchmark will clear all benchmarks currently mapped.",
    customScale: "Survey Items",
    defaultSettings: `Default Scales`,
    newFilter: "New Filter",
    clearFilters: "Clear Filters",
    useSavedFilter: "Use Saved Filter",
    saveFilter: "Save Filter",
    filterNotValid: "Filter not valid",
    nameMustBeUnique: "Name must be unique",
    nameCannotBeEmpty: "Name cannot be empty",
    dateResponseSubmitted: "Last Response Submitted",
    noFilterSaved: "No filter saved",
    noFilterAdded: "No filter added",
    customScaleHelp: [
        `Override Default - Allows the ability to set the custom scales for the question using the dropdowns below.`,
        ` `,
        `From within the settings dropdown: Include in Reports - (default) Question date in included in all aspects of the reports.`,
        ` `,
        `Ignore from Reports - Ignore the question data from all reports.`,
        ` `,
        `Include in Reports but Remove from Overall Dimension Score - Question date will still appear in reports but will not be included in overall score.`,
        ` `,
        `Hide from Restricted Report Viewers - User checks this to hide the question from reports for Restricted Report Viewers only. The question score is still added to the overall score but the user cannot see the breakdown of the data for this question.`
    ],
    defaultScaleCustomized: "Default Scale Customized:",
    defaultScaleUsed: "Default Scale Used:",
    overrideDefault: `Override Default`,
    includeInReports: "Include in Reports",
    removeFromReports: "Remove from Reports",
    noOverallScore: "Include in reports but remove from overall dimension score",
    reportingOptions: "Reporting Options",
    favorable: "Favourable",
    neutral: "Neutral",
    unfavorable: "Unfavourable",
    ignored: "Ignored",
    hideFromRestrictedReportViewer: "Hide from Restricted Report Viewer",
    customizeIndividualQuestions: "Customize Individual Items",
    useNominalScale: "Use Nominal Scale",
    noData: "No Overall Dimension Score Available",
    totalResponses: "Total Responses",
    benchmark: "Benchmark",
    noSearchResults: "No results found.",
    confidentialityWarning: "Confidentiality Warning!",
    confidentialityWarningDescription:
        "The selected filter(s) has a result with a number of responses that is lower than the confidentiality threshold. To preserve the confidentiality of responses, we are unable to show this report.",
    confidentialityWarningDescriptionAllSubGroup:
        "All of the groups in the report are below the confidentiality threshold. To preserve the confidentiality of responses, we are unable to show this report.",
    previousPeriod: "Previous Period",
    noPreviousPeriodMappingCreated: "No Previous Period Mapping Created",
    toContinueEnterInformationBelowAndAutomap: `To continue enter the information below and click "Automap"`,
    accessDenied: "Access Denied",
    userHasNoAccess: "You don't have access to this page.",
    PleaseContactUsForLicense:
        "Please contact  us at support@talentmap.com or your administrator for achieving a license to access this page.",
    previousSurvey: "Previous Survey",
    mappingLabel: "Mapping Label",
    previousSurveyDescription: "Select a previous survey to map to the current survey result.",
    mappingLabelDescription: [
        "Maximum 6 characters.",
        "This label will be displayed on reports where previous periods are used."
    ],
    currentSurvey: "Current Survey",
    mappingToSurvey: "Mapping to Survey",
    showInReports: "Show in Reports",
    deleteMapping: "Delete Mapping",
    mapTo: "Map to",
    remapGroupItems: "Remap Group Items",
    clearMappings: "Clear Mappings",
    clearMapping: "Clear Mapping",
    editMapping: "Edit Mapping",
    clickToAddNewMapping: "Click the plus button to add a new mapping.",
    deleteMappingMsg: "This action will permanently remove the mapping.",
    delete: "Delete",
    mapped: "Mapped",
    previousPeriodMapping: "Previous Period Mapping",
    unableToRemapGroup: "Unable to remap group",
    unableToMapValue: "Unable to map value",
    unableToEditSurveyComparison: "Unable to edit survey comparison",
    unableToAutoMap: "Unable to automap",
    thisActionWillPermanentlyRemoveMapping: "This action will permanently remove the mapping.",
    items: "Items",
    contacts: "Contacts",
    unableToDeleteSurveyComparison: "Unable to delete survey comparison",
    editItemMapping: "Edit Item Mapping",
    responseRateEmptyMessage: "To continue please select a survey in the dropdown found on the top left of the page.",
    openSurveys: "Open Surveys",
    demographics: "Demographics",
    responseRateHelpText: [
        "Use the dropdown to break down the overall response rate by demographic. (For example: View the response rate for each department).",
        "You can breakdown the response rate report further by clicking the plus button to add additional dropdowns (For example: View the response rate for each age group within a department)."
    ],
    responseRateEmptyBreakdownPageText: [
        "The selected filter contains no completed survey responses.",
        "Please update the filter selection."
    ],
    lastUpdated: "Last Updated: ",
    noSurveyCreated: "No Survey Created",
    responseRateNoSurveyCreateMessage: [
        "Reporting data will be available once a survey has been created.",
        "Please contact your administrator if you have any questions."
    ],
    snapshot: "Snapshot",
    overallScores: "Overall Scores",
    snapshotReport: "Snapshot Report",

    close: "Close",
    next: "Next",
    back: "Back",
    finish: "Finish",
    reset: "Reset",
    hierarchyName: "Org Chart Name",
    createHierarchy: "Create Org Chart",
    createPowerPoint: "Create PowerPoint",
    configureCard: "Configure Card",
    existingHierarchies: "Existing Org Charts",
    availableFields: "Available Fields",
    hierarchyFields: "Org Chart Fields",
    dragHereToAdd: "Drag here to add",
    fields: "fields.",
    stackedBarCharts: "stacked bar charts.",
    responseCount: "Response Count",
    childCount: "Child Count",
    exploreComments: "Explore Comments",
    showCorrelations: "Show Correlations",
    collaborator: "Collaborator",
    creator: "Creator",
    info: "Info",
    howTo: "How to",
    tasks: "Tasks",
    assign: "Assign",
    status: "Status",
    title: "Title",
    dueDate: "Due Date",
    goals: "Goals",
    notes: "Notes",
    attachments: "Attachments",
    dropFile: "Drop the file here ...",
    selectFile: "Drag and drop file here or click to select file",
    noActionAssociatedToCard: "There are no actions associated to this card",
    noActionSelected: "There is no action selected",
    surveyInformation: "Survey Information",
    organization: `Organization`,
    organizationTimeZone: `Organization Timezone`,
    surveySections: "Survey Sections",
    commentsEmptyMessageAdmin: "Select section to view word cloud and comments.",
    commentsEmptyMessageManager: "Select section to view word cloud.",
    noTaskToAction: "There is no task associated to this action",
    deleteThisItem: "Delete this item?",
    deleteCannotBeReversed: "This action cannot be reversed and may affect other users.",
    makeDefault: "Make default",
    yes: "Yes",
    no: "No",
    reassignUser:
        "User is currently assigned to a different card and continuing this action will reassign them to this card instead.",
    highlightUnassignedCards: "Highlight Unassigned Cards",
    language: "Language",
    show: `Show`,
    peers: `Peers`,
    parent: `Parent`,
    selectAll: `Select All`,
    deselectAll: `deselect All`,
    new: `New`,
    action: `Action`,
    task: `Task`,
    notStarted: `Not Started`,
    inProgress: `In Progress`,
    complete: `Complete`,
    default: `Default`,
    createHierarchyHelp: [
        `Drag items from Available Fields to Org Chart Fields to build the Org Chart.`,
        `To remove an item from the card drag items from the Org Chart Fields list.`
    ],
    configureCardHelp: [
        `Drag items from the left column to the card to build the default card layout.`,
        `To remove an item from the card drag from the card to the left column.`,
        `The default card layout is the one that will be used and seen by anyone logging in to TalentGate for the first time.`,
        `Set your current card layout as the default by clicking the icon in the top right corner and clicking "Set as default".`
    ],
    notAssigned: `Not assigned`,
    duplicatedHierarchyName: `There is already a Org Chart with this name.`,
    duplicatedActionName: `There is already an action with this name.`,
    duplicatedTaskName: `There is already a task with this name.`,
    setAsDefault: `Set as default`,
    revertToDefault: `Revert to default`,
    clearCard: `Clear card`,
    more: `More`,
    defaultCardLayoutNotSet: `Default card layout not set`,
    hierarchyOutOfDate: `Org Chart is out of date`,
    currentHierarchyOutOfDate: `Current Org Chart is out of date`,
    keyDriver: `Key Driver`,
    keyDrivers: `Key Drivers`,
    allStepsCompleted: `All steps completed`,
    correlations: `Correlations`,
    topCorrelations: `Top Correlations`,
    comments: `Comments`,
    commentsCaps: `COMMENTS`,
    wordCloud: `Word Cloud`,
    wordCloudCaps: `WORD CLOUD`,
    description: `Description`,
    noTaskDescription: `There is no description for this task`,
    commentsDialogAdminHelp: [
        `Search will display comments that match the word`,
        `or words entered.`,
        ` `,
        `As a result the word cloud will display the most`,
        `used words from within the search results.`
    ],
    commentsDialogManagerHelp: [
        `This will search comments for the word or words`,
        `entered. This will change the word cloud,`,
        `displaying the most used words from comments`,
        `that are associated with the search.`
    ],
    noStatusShown: `There is no status to be shown for this action`,
    managerComparisons: `Manager Comparisons`,
    immediateSuperior: `Immediate Superior`,
    notificationSettings: `Notification Settings`,
    turnOnNotification: `Turn on Notifications`,
    managerComparisonsHelp: `Managers can only see the section of the Org Chart that they are assigned. This allows them to see other cards in the hierarchy to allow
        them to compare themselves to the organization as a whole, their immediate superior and their peers within their team.`,
    notificationSettingsHelp: [
        `Notification emails are only sent to users when they have a new notification.`,
        `New notifications include:`,
        `• Assignment to a card`,
        `• Action is shared to a card you are assigned to`,
        `Emails are sent once a day at 9:00 AM (Eastern Standard Time)`
    ],
    notificationWarming: `Notification email will be sent at 9:00 AM (Eastern Standard Time) daily.`,
    dueDateExpired: `Due Date Expired`,
    correlationHelp: [
        `A correlation is a statistical measure of the strength of the relationship between two dimensions`,
        ` `,
        `The darker the color of the square, the stronger the correlation between those two dimensions`
    ],
    actionReport: `Action Report`,
    customScales: `Custom Scales`,
    noDefaultScale: "There is no default scale set",
    noQuestionToCustomScale: "There are no questions to customize scale",
    noScaleNorQuestionSelected: `There is no scale nor question selected`,
    cantDropFile: `This type of file can't be dropped here`,
    fileNotUploaded: `The file was not uploaded, please try again`,
    files: `Files`,
    groupStatus: `Group Status`,
    cardStatus: `Card Status`,
    keyDriversNoDisplay: `Key drivers cannot be displayed with less than 30 responses.`,
    personal: "Personal",
    shared: "Shared",
    cannotSaveNoValidFilter: "Can't save if filter is not valid",
    duplicatedFilterName: `There is already a filter with this name.`,
    ignoreQuestion: "Ignore Question",
    hideForManager: "Hide for Managers",
    noFilesEmptyStateMessage: `No files attached.`,
    turnOnReportFilters: `Turn on Report Filters for Managers`,
    clearFilterCondition: "Clear filter conditions",
    loadFilter: "Load filter",
    addNewCondition: "Add new filter condition",
    clearCondition: "Clear Condition",
    doYouWantToProceed: "Do you want to proceed?",
    thisActionWillChangeLayout: "This action will update the layout of existing hierarchies",
    benchmarkMapping: "Benchmark Mapping",
    selectBenchmarkSection: "Select section",
    selectBenchmarkQuestion: "Select question",
    selectFieldsFilter: "Select all field that should appear in filters.",
    hiddenQuestionWillRemovedDefaultLayout:
        "Hide question for managers will update the default layout. If you are using the default layout, it will update your chart.",
    cannotSetDefaultLayoutWithHiddenQuestion: "Default layout cannot be set with hidden question for managers",
    cannotSetDefaultLayoutWithHiddenSection:
        "Default layout cannot be set when all questions in a section are ignored or hidden for managers",
    displayKeyDriversIsSetOff: "Display Key Drivers for Managers is set off on General Settings",
    keyDriverCannotBePartDefaultLayoutEngagementHidden:
        "Key Drivers cannot be part of default layout if section ENGAGEMENT is hidden or ignored",
    warning: "Warning",
    reportFilters: "Report Filters",
    displayKeyDriversForManagers: "Display Key Drivers for Managers",
    keyDriversWillBeRemovedDefaultLayout: "Key Drivers will be removed from default layout",
    noGoals: "No goals have been entered",
    noDescription: "No description",
    disallowedFilterParagraphs: [
        "The selected filters produce a report differing by the same number or fewer responses as the set confidentiality threshold from a recent report. To preserve the confidentiality of responses, we are unable to show this report.",
        "To preserve the confidentiality of individual responses TalentMap keeps track of the results of the reports run by each user. If the set of people in a given report is too similar to the set of people in a recent report then it can become possible to see if a given person responded positively or negatively to a survey. When this occurs we will block this report from being displayed to you in order to ensure the privacy of those respondents.",
        "For instance, filtering the results to see only the result of men or women is generally a legitimate thing to do as they will often have different responses about things like work/life balance. But if a specific department has only one woman in it, and the results are filtered to include only men, then the effect is to remove a single person from this report. In that case you can easily identify the person who was removed, and if the results shift to be more positive you then know that she responded negatively to the survey.",
        "The system keeps track of the last five reports that were run by each user, and the result of each new filter must differ by at least the set confidentiality threshold number of respondents from one of those recent reports in order for it to be returned."
    ],
    infoNotes: "Notes",
    noOverallAvailable: "No Overall Available",
    displayBenchmarksInCard: "Display Benchmarks in Card",
    cantDisplayBenchmark: "Benchmark cannot be displayed until all items are mapped",
    exportingSnapshotPowerPoint: "Exporting Snapshot Report PowerPoint",
    thereIsNoFilterSaved: "There is no filter saved",
    editHierarchyName: "Edit Org Chart Name",
    actionPlanningTemplate: "Action Planning Template",
    editCardName: "Edit Card Name",
    cardName: "Card Name",
    taskName: "Task Name",
    filterIsNotValid: "At least one filter is not valid",
    order: "Order",
    published: "Published",
    keyDriverHelp: "Key drivers are the most important areas that analysis found affected engagement.",
    slide: {
        snapshotReport: {
            infoTitle: "Understanding Your Report",
            infoText: {
                first: "Unless otherwise stated, the overall dimension score is the mean (average) of all items excluding custom item(s).",
                second: "Your organization’s % favorable score is greater (+) or lower (-) than the benchmark’s % favorable score for that item.",
                third: "Indicates that the benchmark of the item is unavailable for comparison.",
                fourth: "Your organization's current period % favorable score is greater (+) or lower (-) than your organization's previous period % favorable score for that item."
            },
            overallTitle: "Overall Scores",
            filterTitle: "Report Filters Applied",
            endTitle: "Our Confidentiality",
            disclaimer: "No data or reports will be provided unless there are at least five (5) respondants"
        }
    },
    organizationLogo: "Organization Logo",
    dialogUploadLogo: {
        controls: {
            labelUploadLogo: "Upload Logo",
            helperUploadFileType: "PNG or SVG with a transparent background",
            helperUploadNoLogo: "No logo uploaded"
        },
        actions: {
            deleteLogo: "Delete Logo"
        }
    },
    exportPowerPoint: "Export to PowerPoint",
    dialogExportSlide: {
        title: "Export to PowerPoint",
        controls: {
            labelMainTitle: "Title",
            labelSubTitle: "Subtitle",
            helperMainTitle: "Appears on title page and as report page headers",
            errorMainTitle: "Mandatory field",
            helperSubTitle: "Will only appear on the title page"
        }
    },
    errorMessage: {
        fileErrorNoMultiFiles: "Only one file is allowed",
        fileErrorFormatPNGorSVG: "File must be one of the following: PNG or SVG"
    },
    thankYou: "Thank you",
    layoutCardLimitDrop: "Card can have up to 7 bar charts (including key driver).",
    dragChartHere: "Drag here to add key driver or bar charts",
    dragResponseRateHere: "Response rate",
    responseNATooltip: "To protect privacy, when there are less than 5 responses, we will hide the data.",
    errorLoadingOrgChart:
        "There was an error loading the org chart. Please reload the page or select a different Org Chart.",
    engagement: "engagement",
    noSurveyResultSelected: "No Survey Result Selected",
    noSurveyResultSelectedMsg:
        "To view the org chart select a survey result from the dropdown at the top left of the page",
    openMenu: "Open Menu",
    exportToPdf: "Export to PDF",
    exportToExcel: "Export to Excel",
    heatmap: "Heatmap",
    heatmapReport: "Heatmap Report",
    unableToFetchPDF: "Unable to fetch PDF",
    unableToFetchPPTX: "Unable to fetch PowerPoint",
    compareTo: "Compare To",
    cellContent: "Cell Content",
    noDemographicSelected: "No Demographic Selected",
    noDemographicSelectedMsg: "To view heatmap please select a demographic from the dropdown on the left.",
    difference: "Difference",
    overall: "Organization Overall",
    overallFiltered: "Filtered Results",
    noMeaningfulInfo: "We can't show you something meaningful here.",
    noDemographicInfo: "There is no demographic information in the selected survey.",
    noDemographicInfoMeg: "Heatmap is not available for this survey, please select another survey.",
    item: "Item",
    demographicValue: "Demographic Value",
    snapshotExportMsg: [
        "Exporting file .pptx...",
        "File .pptx export is completed, please check your download folder."
    ],
    comparisons: "Comparisons",
    theAbilitytoCompareToOverall:
        "The ability to compare to the overall organization is only available when the report is filtered.",
    compareGroup: "Compare Group",
    heatmapMesg: "Data is rounded to the nearest whole number.",
    snapshotCompareConfidentialityMsg: "The group you are comparing to is below the confidentiality threshold.",
    switchAxis: "Switch Axis",
    heatmapWarningMesg:
        "Groups smaller than minimum threshold are not displayed. See Response Rate Report for details.",
    between: "between",
    commentReport: "Comment Report",
    totalComments: "Total Responses",
    resultsTooSimilar: "Results too similar",
    clearAll: "CLEAR ALL",
    addLookdown: "Add Lookdown Slide(s)",
    lookdownTooltip:
        "Lookdown slides compare sub-groups to the overall group. These slides are placed above the Overall Dimension Scores slide in the order they are created.",
    clickThePlusButtonToAddANewLookdown: "Click the plus button to add a lookdown slide",
    surveyDimensionOrItem: "Survey Dimension or Item",
    allComments: "All Comments",
    demographicsLabels: "Demographic Labels",
    originalDemographicsLabel: "Original Label",
    demographicsLabelDataExample: "Data Example",
    demographicsReportLabels: "Report Label",
    demographicLabelTooltip:
        "Report labels will appear in filters, comparisons, heatmaps, report exports, previous period mapping and snapshot export dialog.",
    reportLabelMustBeUnique: "Report label already exists. Please update.",
    reportLabelCannotBeBlank: "Report label cannot be blank.",
    loading: "Loading...",
    clickThePlusButtonToAddANewHeatmapLookdown: "Click the plus button to add a Heatmap slide",
    addHeatmapLookdown: "Add Heatmap Slide(s)",
    heatmapWarningPrefix: "HEATMAP SLIDE WARNING",
    heatmapWarningSuffix: "has too many values selected and may expand off the slide",
    columnLabel: "Column Label",
    columnLabelHelperText: "Maximum 6 characters",
    columnLabelErrorText: "Limit label to 6 characters",
    value: "Value",
    scales: "SCALES",
    fieldsTab: "FIELDS",
    convertSurveyItemHelper:
        " Convert a survey item (question) field to a demographic field that can be filtered in Snapshot, Heatmap and Comment reports.",
    reportLabel: "Report Label",
    addToDemographics: "Add To Demographics",
    english: "English",
    french: "French",
    spanish: "Spanish",
    portuguese: "Portuguese",
    addResponseRateLookdown: "Add Response Rate Slide(s)",
    clickThePlusButtonToAddANewRRLookdown: "Click the plus button to add a Response Rate slide",
    properties: "Properties",
    showInFirstReportColumn: "Show in First Report Column",
    addSecondMapping: "Add Second Period",
    labelExists: "Mapping Label exists",
    addKeyDriversLookdown: "Add Key Drivers Slide",
    keyDriverslookdownTooltip:
        "Key drivers can only be generated if there are 30 or more survey respondents. Key drivers will appear on a single slide. Add a maximum of 3 (three) key drivers.",
    clickThePlusButtonToAddANewKeyDriversLookdown: "Click the plus button to add a Key Drivers slide",
    responseCountWarning: "The minimum number of respondents required to generate key driver analysis is 30",
    columnLabelHelperText24: "Maximum 30 characters",
    columnLabelErrorText24: "Limit label to 30 characters",
    noItemAvailable: "No Item Score Available",
    correlationReport: "Correlations Report",
    correlationHelpText: [
        `A correlation is a statistical measure of the strength of the relationship between two`,
        ` `,
        `dimensions or items. The darker the color of the square, the stronger the correlation.`
    ],
    notEnoughCorrelationResponse: "Running a correlation report requires at least 30 or more responses.",
    benchmarkTags: "Benchmark Tags",
    addTag: "Add Tag",
    deleteTag: "Delete Tag",
    createNewTag: "Create New Tag",
    thisActionWillPermanentlyRemoveTag:
        "This action cannot be reversed. The tag will be removed from any previously created organizations, surveys, and benchmarks. Data for benchmarks that were already created will not be altered.",
    createdTags: "Created Tags",
    duplicateTag: "Tag already exists.",
    benchmarkInformation: "Benchmark Tags",
    createNewBenchmark: "Create New Benchmark",
    englishTitle: "English Title",
    frenchTitle: "French Title",
    numberOfOrganizations: "# of Organizations",
    numberOfSurveys: "# of Surveys",
    numberOfResponses: "# of Responses",
    archived: "archived",
    activate: "Activate",
    archive: "archive",
    duplicateBenchmarkName: "Duplicate Benchmark Name",
    hideBenchmarkData: "Hide Benchmark Data",
    hidePPData: "Hide Previous Period Data",
    removeFilterSimilarityWarning: "Remove Filter Similarity Warning",
    canViewAllOrganizationReportDataTooltip:
        "Checking this box will allow the user to see all report data for each survey. Leaving it unchecked will allow you to assign the user to a card in the Org Chart. To learn more, click the question mark.",
    removeFilterSimilarityWarningTooltip:
        "Checking this box will allow the user to run filters on small demographic groups. To learn more, click the question mark.",
    clearBenchmarkMappingTitle: "Clear Benchmark Mapping?",
    clearBenchmarkMapping: "Clearing the benchmark will remove all benchmark data from this survey result.",
    dimensionsAndItems: "Dimensions & Items",
    commentCount: "Comment Count",
    wordCloudSettings: "Word Cloud Settings",
    ok: "OK",
    number: "Number",
    showTopWordsPrefix: "Show top",
    showTopWordsSuffix: "words",
    invalidTopWordsNumber: "Invalid Number",
    maximumTopWordNumber: "Maximum 50",
    importStopWords: "Stop Words File (.csv)",
    stopWordsTooltip:
        "Import stop words with each word in a single row using a .csv file. To learn more, click the question mark.",
    stopWordsErrorMustUploadCSV: "Please upload a CSV file",
    stopWords: "Stop Words",
    stopWordExist: "Stop word already exists",
    duplicateStopWords: "Duplicate stop words in csv file",
    resetWordCloudTitle: "Reset Word Cloud?",
    resetWordCloudMessage: "This action cannot be reversed and may affect other users.",
    commentSettings: "Comment Settings",
    preferences: "Preferences",
    hiddenWordsAndPhrases: "Hidden Words / Phrases",
    showCommentMinWordsPrefix: "Show comments of min",
    showCommentMinWordsSuffix: "words",
    importHiddenWords: "Hidden Words/Phrases File (.csv)",
    hiddenWordsTooltip:
        "Import words, names, phrases etc. with each word/phrase in a single row using a .csv file. To learn more, click the question mark.",
    hiddenWordExist: "Hidden word/phrase already exists",
    resetHiddenWordTitle: "Reset Hidden Words?",
    showCommentRandomSamplePrefix: "Only show random sample of",
    showCommentRandomSampleSuffix: "comments per item",
    minmumTopWordNumber: "Minimum 1",
    commentSamplePrefix: "Comment Sample - showing",
    of: "of",
    commentSampleSuffix: "total comments",
    words: "words",
    minimum: "Minimum",
    randomSample: "Random sample",
    commentsLowerCase: "comments",
    maximumOneWord: "Maximum 1 word",
    dimensionScore: "Dimension Score",
    dimsionScoreThreshold: "Threshold 1-5.No more than 0.2 decimals",
    maximumFive: "Maximum 5",
    minimumOne: "Minimum 1",
    invalidNumber: "Invalid number",
    showDimensionScore: "Show dimension score",
    dimensionScoreHighToLow: "Dimension Score - Highest to Lowest",
    dimensionScoreLowToHigh: "Dimension Score - Lowest to Highest",
    noneCapitalize: "None",
    sort: "Sort",
    dimsionScoreFavorable: "Favourable ≥ than",
    dimsionScoreUnfavorable: "Unfavourable ≤ than",
    dimensionScoreTooltip:
        "Set a threshold to view results by dimension score. Greater than / less than scores cannot overlap. To learn more, click the question mark.",
    dimsionScoreCommentSettingsThreshold:
        "Modify threshold of coloured dots by dimension. Greater than / less than scores cannot overlap. To learn more, click the question mark.",
    dimsionColorThreshold: "Dimension colour threshold",
    showSentimentScore: "Show sentiment confidence",
    sentimentScoreHighToLow: "Sentiment - Highest to Lowest",
    sentimentScoreLowToHigh: "Sentiment - Lowest to Highest",
    sentimentScoreCalculationInProgress:
        "Gathering comment sentiment confidence. This may take several minutes to complete.",
    negativeSentiment: "Negative sentiment",
    positiveSentiment: "Positive sentiment",
    neutralSentiment: "Neutral sentiment",
    score: "confidence",
    overallSentiment: "Overall Sentiment",
    positive: "Positive",
    negative: "Negative",
    sentimentScore: "Sentiment Confidence",
    autoTranslate: "Auto-translate",
    sentimentScoreHeader: "Percentage Range",
    sentimentScoreTooltip: "Set the percentage range of sentiment confidence. To learn more, click the question mark.",
    passwordReset: "Email successfully sent.",
    passwordResetError: "Error resetting password.",
    resetPassWord: "Reset Password",
    assignAccess: "Assign Access",
    assignRestrictedReportViewers: "Assign Restricted Report Viewers",
    noRestrictedUser: "No Restricted Report Viewers created",
    filtersUpperCase: "FILTERS",
    filterLabel: "Filter Label(s)",
    applyFilter: "Apply Filter",
    autoTranslationCalculationInProgress: "Auto-translate in progress. This may take several minutes to complete.",
    addusersToStart: "To add user please go to Admin then Users",
    noReportDataAvailable: "No Report Data Available",
    pleaseContactAdministrator: [
        "You have not yet been assigned any reports.",
        "Please contact your administrator for assistance."
    ],
    dashboardLayoutOptions: "Dashboard Designer",
    dashboardTooltip:
        "Add report types to the layout editor by clicking a pencil icon. To learn more, click the question mark.",
    preview: "Preview",
    configurePane: "Configure Dashboard Pane",
    image: "Image",
    pane: "Pane",
    addPNGImage: "Add PNG Image",
    deleteDashboardConfiguration: "Clear Dashboard Configuration?",
    dashboardTitle: "Dashboard Title",
    spanishTitle: "Spanish Title",
    singleDimensionOrItem: "Single Dimension or Item",
    label: "Label",
    columnLabelErrorText50: "Limit label to 50 characters",
    columnLabelHelperText50: "Maximum 50 characters",
    singleScore: "Single Score",
    beingUsed: "being used",
    noDashboardcreated: "No dashboard has been created for this report",
    column: "Column",
    row: "Row",
    size: "Size",
    goToSnapshotReport: "Go to Snapshot Report",
    overallDimension: "Overall Dimensions",
    hidePriveousPeriodData: "Hide Previous Period Data",
    keyDriverOf: "Key Drivers of",
    dimension: "Dimension",
    impact: "Impact",
    belowKeyDriverThreshold: "The minimum number of respondents required to generate key driver analysis is 30.",
    review: "review",
    singleDimensionWithItems: "Single Dimension with Items",
    lookdown: "Lookdown",
    excludedDimensions: "Excluded Dimensions",
    overallDimensionScore: "Overall Dimension Scores",
    bm: "BM",
    map: "Map",
    permissions: "Permissions",
    bulkExport: "Bulk Export",
    isBulkReportingEnabled: "Run Bulk Reports",
    dashboardOverall: "Overall",
    count: "Count",
    tooManyRowsWarning: "There are too many rows of data to display this table correctly.",
    bulkExportPowerPoint: "Bulk Export to PowerPoint",
    bulkStatus: "Bulk Status",
    bulkProgress: "Bulk Report Export Progress",
    startSnapshotBulkExport: "Bulk snapshot report queued. Check status in the downloads page.",
    reportQueued: "Report queued",
    queuePositions: "Queue position",
    reportGenerating: "Generating reports",
    reportCompleted: "Report complete",
    report: "Report",
    showQuestionnaireItemOnly: "Show Questionnaire Items Only",
    startDownloading: "Downloading report",
    favourableUnfavourableItems: "Favourable / Unfavourable Items",
    bulkReportDemographics: "Report Demographic",
    startHeatmapBulkExport: "Bulk heatmap report queued. Check status in the downloads page.",
    startCommentBulkExport: "Bulk comment report queued. Check status in the downloads page.",
    startCorrelationBulkExport: "Bulk correlation report queued. Check status in the downloads page.",
    startDashboardBulkExport: "Bulk dashboard report queued. Check status in the downloads page.",
    bulk: "bulk",
    bulkExportExcel: "Bulk Export to Excel",
    bulkExportPDF: "Bulk Export to PDF",
    queued: "Queued",
    errored: "Errored",
    completed: "Completed",
    noQueuedBulkExports: "No queued bulk exports",
    noErroredBulkExports: "No errored bulk exports",
    noCompletedBulkExports: "No completed bulk exports",
    deleteBulkExport: "Delete Bulk Export",
    deleteCannotBeReversedForBulk: "This action cannot be reversed.",
    deleteAllBulkExport: "Delete All Completed Bulk Exports",
    deleteCompleted: "Delete Completed",
    requestedTime: "Requested Time",
    completedTime: "Completed Time",
    errorInfo: "Error Info",
    overallSS: "Overall",
    removeOverallComment: "Remove Overall Comments Slide",
    insights: "Insights",
    outcome: "Outcome",
    keyItems: "Key Items",
    keyItemBelow30: "This report requires at least 30 or more responses.",
    percentage: "percentage",
    showInHeatmapComparison: "Show in Heatmap Comparisons",
    isInsightPageEnabled: "View Insights Page",
    commentSample: "Comments that relate to this item",
    loadingComments: "Loading comments. This may take several minutes to complete.",
    noCommentInInsightPage: "This survey has no verbatim comments.",
    participants: "Participants",
    open: "Open",
    closed: "Closed",
    noOpenSurvey: "There are no open surveys in your account",
    noClosedSurvey: "There are no closed surveys in your account",
    uploadCSVFile: "Import CSV File",
    statusReport: "Status Report",
    NumberOfParticipantsUpdated: "Number of Participants Updated",
    missingColumnHeader: "Missing Column Header(s)",
    invalidEmails: "Invalid Email(s)",
    copyToClipborad: "COPY TO CLIPBOARD",
    missingEmailField: "Missing Email Field",
    missingEmailValue: "There is a blank cell in the email column",
    clickImportToAddContacts: "Click import to add participants",
    page: "Page",
    confirmDelete: "Confirm Delete",
    deletingParticipant: "Deleting a participant cannot be reversed.",
    uploadStatus: "Upload Status",
    loadingParticipants: "Loading participants",
    duplicateEmailField: "Duplicate email field",
    uploadMayTakesTime: ["Upload may take several minutes to complete.", "Click homepage to continue using Compass."],
    activityLog: "Activity Log",
    createdTime: "Date Created",
    lastResponseSubmittedTime: "Last Response Submitted",
    user: "User",
    fieldAdded: "Demographic Field Added",
    fieldRemoved: "Demographic Field Removed",
    participantCreated: "Participants Created",
    participantUpdated: "Participants Updated",
    participantDeleted: "Participants Deleted",
    noActivityLog: "No user activity",
    andMore: "and more",
    initialImport: "Initial Import",
    saveSuccessfully: "Saved Successfully",
    isParticipantEnabled: "View Participants Page",
    lockPPAndBenchmark: "Lock Benchmark & Previous Period Mapping",
    confirmImport: "Confirm Import",
    confirmImportParticipants: [
        "Ensure file is correct. Incorrect file upload can result in loss of survey responses.",
        "If changes are made to a column label header(s), previous period data will need to be remapped for the affected column(s)."
    ],
    lockMessagePrefix: "Please contact your TalentMap Project Manager or ",
    lockMessageSuffix: " to unlock this survey mapping.",
    opportunity: "Opportunity",
    strength: "Strength",
    noneUpperCase: "None",
    summary: "Summary",
    commonTopicsFrom: "Common Topics from",
    loadingCommentsSummary: "Loading comment summary and suggestions. This may take several minutes to complete.",
    commonSuggestionFrom: "Possible Suggestions to Improve",
    uploadContactsError: "There was an error uploading participants. Please contact ",
    summarySettings: "Summary Settings",
    summaryPrompt: "Summary Prompt",
    suggestionPrompt: "Suggestions Prompt",
    confirmEditPrompt: "Confirm Promp Edit",
    editPromptMessage:
        "Editing a prompt will change the summary and / or suggestions text for all users who have access to comments.",
    aiInsights: "AI Insights",
    updateEmailField: "System will not allow updates to email column header",
    bulkPPT: "BULK PPT",
    bulkEXCEL: "BULK EXCEL",
    bulkPDF: "BULK PDF",
    bulkstatus: "BULK STATUS",
    powerpoint: "POWERPOINT",
    pdf: "PDF",
    anyWord: "Any Word",
    exactMatch: "Exact Text",
    semanticSearch: "Meaning",
    ask: "ASK",
    loadingCommentsAsk: "Generating answer...",
    commentAskAnswer: "Get AI-generated answers to questions about ",
    typeYourQuestion: "Type your question here",
    confirmNewCommentConversation: "All text will be removed.",
    tour: "Tours",
    clear: "Clear",
    clearConversation: "Clear Conversation",
    benchmarkData: "Benchmark",
    pPData: "Previous Period",
    other: "Other",
    reportError: "An error occurred",
    pleaseContact: "Please contact",
    group: "Group",
    siteAdmin: "Site Admin",
    restrictedReportViewer: "Restricted Report Viewer",
    reportViewer: "Report Viewer",
    never: "Never",
    lastAccess: "Last Access",
    itemBelowConfidentialityThreshold: "Item is below confidentiality threshold",
    previousPeriodMappingExporting: "Exporting file PreviousPeriod.xlsx...",
    previousPeriodMappingExportCompleted:
        "File Previous Period Export.xlsx export is completed, please check your download folder.",
    knowledgeBase: "Knowledge Base",
    whatIsNew: "What's New",
    duplicateHeader: "Duplicate Headers",
    clearDimensionMappings: "Clear Dimension Mappings?",
    thisActionWillUnmapItemsMapping: "This action will un-map all items within the dimension.",
    lookup: "Lookup",
    addLookupLookdown: "Add Lookup / Lookdown Slides",
    lookupLookdownTooltip:
        "Lookup / lookdown allows you to to isolate a group, then see the score for a particular item or dimension and choose to see what is above and below the group. To learn more, click the question mark.",
    includeLookupOrgOverall: "Include Organization Overall",
    includeWordCloud: "Include comment word cloud(s)",
    downloads: "Downloads",
    bulkReportDownloads: "Bulk Report Downloads",
    bulkDemographicSame: "Bulk demographic and lookdown fields cannot be the same.",
    selectOrg: "Select Organization",
    noCreatedBulkExports: "No Bulk Reports Created",
    resend: "Resend Invite",
    editParticipantsWarning:
        "Changes to this list are not permitted as the survey is currently mapped to another survey for previous period data. To make changes, please first unmap the survey that is using this participant list.",
    allowInReports: "Available for Reporting",
    orgEngagementScore: "Org Engagement Score"
};
